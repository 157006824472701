export var Environment;
(function (Environment) {
    Environment["Local"] = "LOCAL";
    Environment["Dev"] = "DEVELOPMENT";
    Environment["Staging"] = "STAGING";
    Environment["Prod"] = "PRODUCTION";
})(Environment || (Environment = {}));
export var OAuthGrantType;
(function (OAuthGrantType) {
    OAuthGrantType["AuthorizationCode"] = "AUTHORIZATION_CODE";
    OAuthGrantType["Implicit"] = "IMPLICIT";
})(OAuthGrantType || (OAuthGrantType = {}));
