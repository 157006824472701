import { ThreeEvent } from '@react-three/fiber';
import React, { FunctionComponent, useMemo, useState, useCallback, useEffect, memo, useRef, useLayoutEffect, SyntheticEvent, useImperativeHandle, forwardRef } from 'react';
import { Intersection, LinearSRGBColorSpace, NoColorSpace, Object3D, SRGBColorSpace, TextureLoader } from 'three';
import { ITuple3, ITuple4, IVideoReactProps } from '../../component-data-structure';
import { useHlsLoader, useRefState } from '../../hooks';
import { useTextureLoader } from '../../hooks/useTextureLoader';
import { getScreenRelativeComponentUserData, getUserDataFromIntersections, isHighestRenderOrder, IUserData } from '../../utils/general';
import CurvedEntity from '../CurvedEntity/CurvedEntity';
import Spinner from '../Spinner';
import PlayPauseButton from './PlayPauseButton';

export interface IVideoObject3D extends Object3D {
	_onVideoPointerDown: (intersections: Intersection[]) =>  void;
	_onVideoPointerup: (intersections: Intersection[]) =>  void
}

// REFACTOR: this needs refactoring to avoid having to pass screenRelative ids down to video component.
// Ideally we want to use the existing onPointerDown handler in the HOC with all its existing highest render order filter logic
// to change the video state (i.e. show the pause, play button etc). This video component should be much dumber..
// Along with that we want to have different HOCs for different component types to avoid a bloated HOC.

const Video = forwardRef<IVideoObject3D, IVideoReactProps>(({
	id,
	rotation: r,
	position: p,
	scale: s,
	videoUrl,
	thumbnailUrl,
	opacity = 1,
	autoplay = false,
	loop = false,
	isPlaying = false,
	videoRunCounter: runCounter,
	enabled,
	hideControls: hideCtrls = false,
	hasAlpha = false,
	renderOrder,
	thumbnailOnly = false,
	curvature = 0,
	onPointerUp,
	onPointerDown,
	onPointerMove,
	onDoubleClick,
	onFullScreen,
	onVideoStateChange,
	borderRadius,
	borderWidth,
	borderRgba,
	siblings = null,
	screenRelativeIds,
	raycast,
	chromaKey,
	onCanPlayThrough,
	onVideoNodeReady,
	castShadow = false,
	receiveShadow = false,
	animationTransformGroupPrefix = '',
	audioContext,
	audioStreamDestination,

}, ref) => {
	const timeoutRef = useRef<NodeJS.Timeout>(null!);
	const [isWaiting, setIsWaiting] = useState(true);
	const [initialBtnHide, setInitialBtnHide] = useState(autoplay);
	const [paused, setPaused, pausedRef] = useRefState(true);
	const [, setShowPause, showPauseRef] = useRefState(false);
	const [hideButtons, setHideButtons, hiddenButtonsRef] = useRefState(false);
	const [hasBeenClicked, setHasBeenClicked, hasBeenClickedRef] = useRefState(false);
	const pointerDownRef = useRef(false);

	// Import thumbnail
	const thumbnailTexture = useTextureLoader(TextureLoader, thumbnailUrl || '', false);

	const rotation = useMemo(() => r as ITuple3, [r]);
	const position = useMemo(() => p as ITuple3, [p]);
	const scale = useMemo(() => s as ITuple3, [s]);
	const spinnerScale = useMemo(() => [s[1] * 0.3, s[1] * 0.3, 0] as ITuple3, [s]);

	const playButtonScale: ITuple3 = useMemo(() => {
		const x = Math.abs(scale[0]);
		const y = Math.abs(scale[1]);
		const z = 0;
		return x < y ? [x * 0.2, x * 0.2, z] : [y * 0.2, y * 0.2, z];
	}, [scale]);

	const userData: IUserData = useMemo(() => ({ renderOrder, contentId: id }), [id, renderOrder]);
	// TODO - Temporary, fix
	const isChromaKey = hasAlpha && !!chromaKey;
	let showThumbnail = useMemo(() => !!thumbnailUrl && !hasBeenClicked && !autoplay && !isChromaKey, [thumbnailUrl, hasBeenClicked, isChromaKey, autoplay]);
	if (thumbnailOnly) showThumbnail = true;

	const hideControls = hideCtrls || (loop && autoplay);
	const showButton = !initialBtnHide && !hideControls && !hideButtons;

	// Set up video texture, suspend component until texture available
	const [vidTexture, video] = useHlsLoader(videoUrl, id, {
		muted: false,
		loop,
		autoplay,
		preventVideoLoad: thumbnailOnly,
		audioContext,
		audioStreamDestination
	});

	if (vidTexture) {
		onVideoNodeReady?.(video);
		vidTexture.colorSpace = hasAlpha ? LinearSRGBColorSpace : SRGBColorSpace;
	}

	const resetVideoUI = useCallback(() => {
		setHasBeenClicked(false);
		setHideButtons(autoplay);
		setPaused(true);
	}, [setHideButtons, autoplay, setHasBeenClicked, setPaused]);

	const showPlayVideoUI = useCallback(() => {
		if (thumbnailOnly) return;
		if (timeoutRef) clearTimeout(timeoutRef.current);
		setHideButtons(false);
		setPaused(false);
		timeoutRef.current = setTimeout(() => {
			setHideButtons(true);
		}, 2000);
	}, [thumbnailOnly, setHideButtons, setPaused, timeoutRef]);

	const showPauseVideoUI = useCallback(() => {
		if (thumbnailOnly) return;
		clearTimeout(timeoutRef.current);
		setHideButtons(false);
		setShowPause(false);
		setPaused(true);
	}, [thumbnailOnly, setHideButtons, setShowPause, setPaused]);

	const showPauseTemporaryVideoUI = useCallback(() => {
		if (thumbnailOnly) return;
		if (timeoutRef) clearTimeout(timeoutRef.current);
		setHideButtons(false);
		setShowPause(true);
		timeoutRef.current = setTimeout(() => {
			setShowPause(false);
			setHideButtons(true);
		}, 2000);
	}, [setHideButtons, setShowPause, thumbnailOnly, timeoutRef]);

	useEffect(() => {
		if (thumbnailOnly) return;
		const onEndedHandler = () => {
			if (loop) return;
			resetVideoUI();
			onVideoStateChange?.({ isPlaying: false, currentTime: 0, hasFinished: true });
		};
		const onWaitingHandler = () => setIsWaiting(true);
		const onPlayingHandler = () => setIsWaiting(false);
		const onCanPlayHandler = () => setIsWaiting(false);
		const onCanPlayThroughHandler = () => onCanPlayThrough?.(video);

		video?.addEventListener('waiting', onWaitingHandler);
		video?.addEventListener('playing', onPlayingHandler);
		video?.addEventListener('canplay', onCanPlayHandler);
		video?.addEventListener('ended', onEndedHandler);
		video?.addEventListener('canplaythrough', onCanPlayThroughHandler);

		return () => {
			video?.removeEventListener('waiting', onWaitingHandler);
			video?.removeEventListener('playing', onPlayingHandler);
			video?.removeEventListener('canplay', onCanPlayHandler);
			video?.removeEventListener('ended', onEndedHandler);
			video?.removeEventListener('canplaythrough', onCanPlayThroughHandler);
		};
	}, []);

	useLayoutEffect(() => {
		if (!enabled || thumbnailOnly || !isPlaying) return;
		if (autoplay) {
			setHideButtons(true);
			return;
		}
		showPlayVideoUI();
		setHasBeenClicked(true);
	}, [enabled, thumbnailOnly, isPlaying, autoplay, runCounter]);

	// If video is stopped programmatically (i.e. no user interaction (no previous pointerdown event on video element)
	// show pause video ui if hide controls if false
	useEffect(() => {
		if (thumbnailOnly) return;
		if (!isPlaying && !hideCtrls) {
			showPauseVideoUI();
		}
	}, [thumbnailOnly, isPlaying, showPauseVideoUI]);

	// Reset video if disabled (e.g. in exit phase)
	// TODO: remove this by combining with previous useEffect
	useEffect(() => {
		if (enabled || thumbnailOnly) return;
		resetVideoUI();
		if (isPlaying) onVideoStateChange?.({ isPlaying: false, currentTime: 0 });
	}, [enabled, thumbnailOnly, resetVideoUI, onVideoStateChange, isPlaying]);



	const onVideoPointerDown = useCallback(
		(intersections: Intersection[], e: ThreeEvent<PointerEvent>) => {
			onPointerDown?.(e);
			if (thumbnailOnly) return;
			let userData = getUserDataFromIntersections(intersections);
			let siblingIds = siblings || [];
			
			// If userData contains a screen relative ID, and it's not this video then return early
			for (const data of userData) {
				if (screenRelativeIds?.includes(data.contentId) && data.contentId !== id) return;
			}

			if (screenRelativeIds?.includes(id)) {
				userData = getScreenRelativeComponentUserData(userData, screenRelativeIds);
				siblingIds = screenRelativeIds;
			}

			if (!enabled || !isHighestRenderOrder(userData, renderOrder, siblingIds)) return;
			pointerDownRef.current = true;
			if (hasBeenClickedRef.current) return;
			setHasBeenClicked(true);
		},
		[enabled, siblings, renderOrder, setHasBeenClicked, thumbnailOnly, onPointerDown, hasBeenClickedRef, screenRelativeIds]
	);

	const onVideoPointerUp = useCallback(
		(intersections: Intersection[]) => {
			if (thumbnailOnly) return;
			if (!pointerDownRef.current) return;
			pointerDownRef.current = false;
			let userData = getUserDataFromIntersections(intersections);
			let siblingIds = siblings || [];

			// If userData contains a screen relative ID, and it's not this video then return early
			for (const data of userData) {
				if (screenRelativeIds?.includes(data.contentId) && data.contentId !== id) return;
			}

			if (screenRelativeIds?.includes(id)) {
				userData = getScreenRelativeComponentUserData(userData, screenRelativeIds);
				siblingIds = screenRelativeIds;
			}

			if (!enabled || (loop && autoplay) || !isHighestRenderOrder(userData, renderOrder, siblingIds)) return;

			setInitialBtnHide(false);
			if (pausedRef.current) {
				showPlayVideoUI();
				onVideoStateChange?.({ isPlaying: true });
			} else if (!pausedRef.current && showPauseRef.current) {
				showPauseVideoUI();
				onVideoStateChange?.({ isPlaying: false });
			} else if (!pausedRef.current) {
				if (!hiddenButtonsRef.current) {
					showPauseVideoUI();
					onVideoStateChange?.({ isPlaying: false });
				} else showPauseTemporaryVideoUI();
			}
		},
		[
			thumbnailOnly,
			enabled,
			loop,
			autoplay,
			renderOrder,
			siblings,
			setInitialBtnHide,
			onVideoStateChange,
			showPlayVideoUI,
			showPauseVideoUI,
			showPauseTemporaryVideoUI,
			hiddenButtonsRef,
			pausedRef,
			showPauseRef,
			screenRelativeIds,
		]
	);

	useImperativeHandle(ref, () => {
		return {
		  _onVideoPointerup(intersections: Intersection[]) {
			onVideoPointerUp(intersections);
		  },
		  _onVideoPointerDown(intersections: Intersection[], e: ThreeEvent<PointerEvent>) {
			onVideoPointerDown(intersections, e);
		  },
		} as IVideoObject3D;
	}, [onVideoPointerUp, onVideoPointerDown]);


	const _onFullScreen = useCallback(
		(e: ThreeEvent<MouseEvent>) => {
			if (thumbnailOnly) return;
			if (!enabled || loop || hasAlpha) return;
			e.stopPropagation();
			setHasBeenClicked(true);
			onVideoStateChange?.({ isPlaying: true });
			setPaused(false);
			if (video && onFullScreen) onFullScreen(video, e as any);
		},
		[video, loop, enabled, hasAlpha, setPaused, thumbnailOnly, onFullScreen, setHasBeenClicked, onVideoStateChange]
	);

	return (
		<group
			raycast={raycast}
			key={'video_group'}
			name={`${animationTransformGroupPrefix}${id}`}
			userData={userData}
			position={position}
			rotation={rotation}
			onPointerUp={onPointerUp || undefined} // active even when disabled (needed for d2 editor)
			// onPointerDown={onPointerDown || undefined }
			onPointerMove={onPointerMove || undefined}
			onDoubleClick={onDoubleClick || undefined}
		>
			<group name={`${animationTransformGroupPrefix}inner_${id}`}>
				{((showThumbnail && thumbnailTexture) || (!showThumbnail && vidTexture && !isWaiting)) && (
					<CurvedEntity
						chromaKey={chromaKey}
						raycast={raycast}
						ref={ref}
						renderOrder={renderOrder}
						name={'Video Background'}
						scale={scale}
						opacity={opacity}
						cornerRadius={borderRadius || 0}
						curvature={curvature}
						hasAlpha={hasAlpha}
						videoTexture={!showThumbnail ? vidTexture : undefined}
						imageTexture={showThumbnail ? thumbnailTexture : undefined}
						colorRgba={thumbnailTexture === null ? [0, 0, 0, 1] : undefined}
						stackedAlpha={showThumbnail ? false : hasAlpha && !isChromaKey}
						onPointerDown={e => onVideoPointerDown(e.intersections, e)}
						onPointerUp={e => onVideoPointerUp(e.intersections)}
						onDoubleClick={_onFullScreen}
						castShadow={castShadow}
						receiveShadow={receiveShadow}
						border={{
							borderSize: borderWidth || 0,
							borderRgba: borderRgba as ITuple4,
						}}
					/>
				)}
				<PlayPauseButton
					scale={playButtonScale}
					showPlayIcon={true}
					entityWidth={scale[0]}
					renderOrder={renderOrder + 1}
					raycast={raycast}
					curvature={curvature}
					visible={(paused || !hasBeenClicked) && showButton}
				/>
				<PlayPauseButton
					scale={playButtonScale}
					showPlayIcon={false}
					entityWidth={scale[0]}
					renderOrder={renderOrder + 1}
					raycast={raycast}
					curvature={curvature}
					visible={!(paused || !hasBeenClicked) && showButton}
				/>
				{isWaiting && enabled && <Spinner opacity={1} raycast={raycast} radiansPerFrame={0.06} color={'#d9d9d9'} scale={spinnerScale} depthWrite={false} />}
			</group>
		</group>
	);
});

Video.displayName = 'Video';
export default memo(Video);
