import { Color, ColorSpace, Intersection, MathUtils, NoColorSpace, Object3D, Path, Shape, Vector3 } from 'three';
import { FONT_TYPES, IAbstractComponentUnion, IActionCategory, IButton, IButtonSubCategory, IComponentType, IComponentUnion, ICurveComponentUnion, IFontTypes, ISceneComp, IScreenAnchorGroup, IScreenAnchorPositionType, IScreenContent, ITriggerTypes, ITuple3, ITuple4 } from '../component-data-structure';
import { getScreenContentIdForScene } from './pure';
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

export interface IUserData {
	contentId: string;
	renderOrder?: number;
	enabled?: boolean;
}

export const numberHasDecimals = (num: number) => {
	return num % 1 != 0
}

export const toRadians = (degrees: number): number => (degrees * Math.PI) / 180;

export const createRoundedRectShape = (
	scale: ITuple3,
	r: number,
	holePath: Path
) => {
	const x = 0;
	const y = 0;
	const width = Math.abs(scale[0]);
	const height = Math.abs(scale[1]);
	let radius = r * (height / 2);

	const aspectRatio = width / height;
	if (aspectRatio < 1) radius *= aspectRatio;

	const radiusPerc = ((radius || 0) / (height / 2)) * 100;
	const roundedRectShape = new Shape();

	if (radiusPerc > 0 && (radiusPerc < 100 || aspectRatio < 1)) {
		roundedRectShape
			.moveTo(x, y + radius)
			.lineTo(x, y + height - radius)
			.quadraticCurveTo(x, y + height, x + radius, y + height)
			.lineTo(x + width - radius, y + height)
			.quadraticCurveTo(x + width, y + height, x + width, y + height - radius)
			.lineTo(x + width, y + radius)
			.quadraticCurveTo(x + width, y, x + width - radius, y)
			.lineTo(x + radius, y)
			.quadraticCurveTo(x, y, x, y + radius);
	} else if (radiusPerc === 0) {
		roundedRectShape
			.moveTo(x, y)
			.lineTo(x, y + height)
			.lineTo(x + width, y + height)
			.lineTo(x + width, y)
			.lineTo(x, y);
	} else if (radiusPerc === 100) {
		const rad = height / 2;
		roundedRectShape
			.moveTo(x + rad, y)
			.absarc(x + rad, y + rad, rad, (Math.PI * 3) / 2, Math.PI / 2, true)
			.lineTo(width - rad, height)
			.absarc(width - rad, rad, rad, Math.PI / 2, (Math.PI * 3) / 2, true)
			.lineTo(x + rad, y);
	}

	if (!!holePath) {
		if (holePath.curves.length) roundedRectShape.holes.push(holePath);
	}

	return roundedRectShape;
};

export const createRoundedRectInnerForm = <T extends Path | Shape>(
	form: T,
	scale: ITuple3,
	r: number,
	bw: number,
	isShape?: boolean
) => {
	const x1 = isShape ? 0 : bw;
	const y1 = isShape ? 0 : bw;
	let width = Math.abs(scale[0]);
	let height = Math.abs(scale[1]);
	let radius = r * (height / 2);
	if (width / height < 1) radius *= width / height;
	let innerRadius = ((height - bw * 2) / height) * radius;
	if (width / height < 1) innerRadius = ((width - bw * 2) / width) * radius;
	const radiusPerc = ((radius || 0) / (height / 2)) * 100;
	const innerRadiusPerc = ((innerRadius || 0) / (height / 2)) * 100;
	width = width - bw * 2;
	height = height - bw * 2;

	if (radiusPerc === 100 && !(width / height < 1)) {
		let rad = height / 2;
		if (width / height < 1) rad = width / 2;
		form
			.moveTo(x1 + rad, y1)
			.absarc(x1 + rad, y1 + rad, rad, (Math.PI * 3) / 2, Math.PI / 2, true)
			.lineTo(width - rad, height + y1)
			.absarc(
				width - rad + x1,
				rad + y1,
				rad,
				Math.PI / 2,
				(Math.PI * 3) / 2,
				true
			)
			.lineTo(x1 + rad, y1);
	} else if (
		innerRadiusPerc > 0 &&
		(innerRadiusPerc < 100 || width / height < 1)
	) {
		form
			.moveTo(x1, y1 + innerRadius)
			.lineTo(x1, y1 + height - innerRadius)
			.quadraticCurveTo(x1, y1 + height, x1 + innerRadius, y1 + height)
			.lineTo(x1 + width - innerRadius, y1 + height)
			.quadraticCurveTo(
				x1 + width,
				y1 + height,
				x1 + width,
				y1 + height - innerRadius
			)
			.lineTo(x1 + width, y1 + innerRadius)
			.quadraticCurveTo(x1 + width, y1, x1 + width - innerRadius, y1)
			.lineTo(x1 + innerRadius, y1)
			.quadraticCurveTo(x1, y1, x1, y1 + innerRadius);
	} else if (innerRadiusPerc === 0) {
		form
			.moveTo(x1, y1)
			.lineTo(x1, y1 + height)
			.lineTo(x1 + width, y1 + height)
			.lineTo(x1 + width, y1)
			.lineTo(x1, y1);
	}
	return form;
};

// Predicate function returns true if IUserData is enabled for this item ( so we don't select disabled entities )
const hasEnabledUserData = (userData: IUserData) => !!userData && !(typeof (userData.enabled) !== 'undefined' && !userData.enabled);

export const hasScreenRelativeIntersection = (userDataArray: IUserData[], screenRelativeIds: string[]): boolean => {
	let hasScreenRelativeIntersection = false
	for (let i = 0; i < userDataArray.length; i++) {
		const userData = userDataArray[i];
		if (userData && screenRelativeIds.includes(userData.contentId)) {
			hasScreenRelativeIntersection = true;
		}
	}
	return hasScreenRelativeIntersection;
}

export const getScreenRelativeComponentUserData = (userDataArray: IUserData[], screenRelativeIds: string[]): IUserData[] => {
	return userDataArray.reduce((srUserDataArray, userData) => {
		if (screenRelativeIds.includes(userData.contentId)) {
			srUserDataArray.push(userData)
		}
		return srUserDataArray;
	}, [] as IUserData[])
}

// Given an array of intersections, strips out any non entity intersections ( NB returns IUserData, not a filtered Intersection array )
export const getUserDataFromIntersections = (intersections: Intersection[]): IUserData[] => {
	let userData: IUserData | null = null;
	const contentIds: string[] = [];
	const userDataArray: IUserData[] = [];
	for (let i = 0; i < intersections.length; i++) {
		userData = findParentUserData(intersections[i]);
		// console.log({userData});
		
		if (userData
			&& !userData.contentId.includes('TransformControls')
			&& userData.renderOrder
			&& userData.renderOrder > 0
			&& !contentIds.includes(userData.contentId)
		) {
			userDataArray.push(userData);
			contentIds.push(userData.contentId);
		}
	}
	return userDataArray;
}

// Tie break when 2x entities are closest ( smallest intersection distance ) AND there are > 1 of them
export const tieBreakCondition = (intersections: Intersection[], sceneChildrenIds: string[]) => {
	const entities: { entityId: string; renderOrder: number; distance: number }[] = [];
	const entityIds: string[] = [];

	// console.log('INTERSECTIONS ', intersections);	
	// Process intersections array to just entityId, renderOrder, distance for analysis
	for (let i = 0; i < intersections.length; i++) {
		const userData = findParentUserData(intersections[i]);
		if (userData
			&& sceneChildrenIds.includes(userData.contentId)
			&& !entityIds.includes(userData.contentId)) {
			entityIds.push(userData.contentId);
			entities.push({
				entityId: userData.contentId,
				renderOrder: userData.renderOrder!,
				distance: Number(intersections[i].distance.toFixed(4))	// 4 decimal places seems to strip out miniscule differences 
			});
		}
	}
	// console.log('ENTITIES: ', entities);
	
	
	// Get the smallest distance to the camera ( closest )
	const smallestDistance = entities.reduce((acc, entityData) => {
		if (!acc) return entityData.distance;	// First entry
		return (entityData.distance < acc ? entityData.distance : acc); 
	}, 0);

	// How may entities are this close?
	const smallestCount = entities.reduce((acc, entityData) => {
		return (entityData.distance == smallestDistance ? acc + 1 : acc);
	}, 0);

	// If more than one, we have a tie break
	return smallestCount > 1;
}

const filterUserDataToActiveScene = (userDataArray: IUserData[], sceneChildrenIds?: string[]) => {
	return (Array.isArray(sceneChildrenIds)
	? userDataArray.filter((userData) => hasEnabledUserData(userData) && (sceneChildrenIds.includes(userData.contentId) || userData.contentId.includes('Gizmo')))
	: userDataArray.filter((userData) => hasEnabledUserData(userData)) as { contentId: string, renderOrder: number }[]);
}

export const containsTransformControlsGizmo = (intersections: Intersection[]) => {	
	// console.log(intersections);
	
	// for (let i = 0; i < intersections.length; i++) {
	// 	let userData = findParentUserData(intersections[i]);
	// 	console.log(userData);
	// }
	for (let i = 0; i < intersections.length; i++) {
		const userData = findParentUserData(intersections[i]);
		if (userData && userData.contentId.includes('TransformControlsGizmo')) return true;
	}
	return false;
}

export const containsScaleMarker = (intersections: Intersection[]) => {	
	// console.log(intersections);
	
	// for (let i = 0; i < intersections.length; i++) {
	// 	let userData = findParentUserData(intersections[i]);
	// 	console.log(userData);
	// }
	for (let i = 0; i < intersections.length; i++) {
		const userData = findParentUserData(intersections[i]);
		if (userData && userData.contentId.includes('ScaleMarker')) return true;
	}
	return false;
}

/**
 * Bit of a strange one - due to "bug" in withEntityDefaults ( react / r3f / caching ) sometimes the entity ID / render order are out of sync ( stale id )
 * this returns true if the entity ID isn't in the intersections array. Bit of a brute force "fix" to the bug - ideally we'd refactor to eliminate the stale
 * state rather than work around it
 * */ 
export const falseIntersect = (id: string, intersections: Intersection[]) => {
	for (let i = 0; i < intersections.length; i++) {
		const userData = findParentUserData(intersections[i]);
		if (userData?.contentId == id) return false; // Not a false intersect if we find it
	}
	return true; // If not found in the array
}

/* 
 The intersections don't include entity data except render order so we have to use that to identify which is closest
 The intersections array is in order from closest to furthest but includes many non-spatial-entity intersections so 
 strip these out first and then if the first ( position [0] ) remaining intersection has the same render order as this
 entity, then it must be the closest to the camera
*/
export const isClosestToCamera = (userDataArray: IUserData[], renderOrder: number, sceneChildrenIds?: string[]): boolean => {
	// const spatialEntityIntersections = removeNonEntityIntersections(intersections);
	const activeSceneSpatialEntityIntersections = filterUserDataToActiveScene(userDataArray, sceneChildrenIds);	
	// console.log('CLOSEST TO CAMERA, activeSceneSpatialEntityIntersections ', activeSceneSpatialEntityIntersections);
	
	if (activeSceneSpatialEntityIntersections.length === 0) return false;
	return activeSceneSpatialEntityIntersections[0].renderOrder === renderOrder;
}


export const isHighestRenderOrder = (userDataArray: IUserData[], renderOrder?: number, sceneChildrenIds?: string[]): boolean => {
	let highestRenderOrder = 0;
	// const spatialEntityIntersections = removeNonEntityIntersections(intersections);
	// console.log(spatialEntityIntersections);
	
	const activeSceneSpatialEntityIntersections = filterUserDataToActiveScene(userDataArray, sceneChildrenIds);
	// console.log(activeSceneSpatialEntityIntersections);
	if (activeSceneSpatialEntityIntersections.length === 0) return false;

	let i = 0;
	while (i < activeSceneSpatialEntityIntersections.length) {
		const { renderOrder } = activeSceneSpatialEntityIntersections[i];
		const ro = renderOrder || 0;
		if (ro > highestRenderOrder) highestRenderOrder = ro;
		i++;
	}
	// console.log('Highest Render Order: ', highestRenderOrder);
	
	return highestRenderOrder <= (renderOrder as number);
}

const findParentUserData = (intersection: Intersection) => {
	const object = intersection.object;
	const hasUserData = (object: Object3D) => !!Object.keys(object.userData).length && "renderOrder" in object.userData && "contentId" in object.userData;
	// console.log(object);
	/**
	 * The second conditional here is required to deal with TransformControls where it's possible the have an active gizmo 
	 * being the translate mode, and for another mode ( eg. rotate ) to interfere because it's set up as follows:
	 * Rotate-Axis-Gizmo - has contentId, renderOrder and visible true -- and the PARENT node has visible false
	 * This isn't the most robust check possible, but seems to be sufficient for this use case
	 */
	if (hasUserData(object) && (!object.parent || !!object?.parent?.visible)) {
		return object.userData as IUserData;
	}
	let {parent: ancestor} = object;
    while (ancestor) {
        if (hasUserData(ancestor)) return ancestor.userData as IUserData;
        ancestor = ancestor.parent;
    }
    return null;
}

export default function mergeRefs<T>(
	refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
  ): React.RefCallback<T> {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === "function") {
			ref(value);
			} else if (ref != null) {
			(ref as React.MutableRefObject<T | null>).current = value;
			}
		});
	};
}

export const diffBySingleCharacter = (str1: string, str2: string) => {
  // If same str length, no diff
  if (str1.length === str2.length) return false;
  // If > 1 diff in length, doesn't diff by single char
  if (Math.abs(str1.length - str2.length) > 1) return false;

  let diffCount = 0;
  const longestStrLength = str1.length > str2.length ? str1.length : str2.length;
  const str1IsLongest = str1.length > str2.length;
  for (let i = 0; i < longestStrLength; i++) {
    if (str1[i + (str1IsLongest ? diffCount : 0)] !== str2[i + (str1IsLongest ? 0 : diffCount)])diffCount++;
  }
  return diffCount === 1;
};

export function isSafariWithAntialiasingBug(): boolean {
    const userAgent = window.navigator ? window.navigator.userAgent : null;
    // 15.4 is known to be buggy.
    // 15.5 may or may not include the fix. Mark it as buggy to be on the safe side.
    return !!(userAgent && (userAgent.match('Version/15.4') || userAgent.match('Version/15.5') || userAgent.match(/CPU (OS|iPhone OS) (15_4|15_5) like Mac OS X/)));
}


export const isSafari = (): boolean => {
	return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
}

export function isSafariWithAudioContextBug(): boolean {
    const userAgent = window.navigator ? window.navigator.userAgent : null;
    // 15.4 is known to be buggy.
    // 15.5 may or may not include the fix. Mark it as buggy to be on the safe side.
    return !!(userAgent && (userAgent.match('Version/17.0') || userAgent.match(/CPU (OS|iPhone OS) (17_0) like Mac OS X/)));
}

export const isAndroid = (): boolean => {
	return /android/i.test(navigator.userAgent ?? '')
}

export const isMultipleOfTwo = (num: number): boolean => {
	return num % 2 === 0
}

export const filterArrayByAnother = (arr1: string[] , arr2: string[]) => {
	const filtered = arr1.filter(el => {
		return arr2.indexOf(el) === -1;
	});
	return filtered;
 };

 export const isAbstractComponentType = (type?: IComponentType): boolean => {
	return (
		!!type && (
			type === IComponentType.Root ||
			type === IComponentType.Scene ||
			type === IComponentType.ScreenContent ||
			type === IComponentType.ScreenAnchorGroup ||
			type === IComponentType.FaceLandmarkGroup
		)
	);
 }

 export const isAbstractComponent = (component: IComponentUnion): component is IAbstractComponentUnion => {
		return isAbstractComponentType(component.type);
 };

export const isCurveComponentType = (type?: IComponentType): boolean => {
	return (
		!!type && (
			type === IComponentType.Button ||
			type === IComponentType.Text ||
			type === IComponentType.Image ||
			type === IComponentType.Video
		)
	);
}

export const isCurveComponent = (component: IComponentUnion): component is ICurveComponentUnion => {
	return isCurveComponentType(component.type);
 }

export const getAnchorGroupIdAtPositionForScene = (scene: ISceneComp, position: IScreenAnchorPositionType, componentsById: { [k: string]: IComponentUnion }) => {
	const screenContentId = getScreenContentIdForScene(scene, componentsById);
	if (!screenContentId) return undefined;
	const anchorGroupIds = (componentsById[screenContentId] as IScreenContent).children;
	for (let i = 0; i < anchorGroupIds.length; i++) {
		const anchorGroup = componentsById[anchorGroupIds[i]];
		if ((anchorGroup as IScreenAnchorGroup).anchorPositionType == position) {
			return anchorGroupIds[i];
		}
	}
}

export const getSnapshotButtonId = (componentsById: { [id: string]: IComponentUnion } | null, activeSceneId: string | null): string => {
	let snapshotButtonId = '';
	if (!componentsById || !activeSceneId) return snapshotButtonId;
	const scene = componentsById[activeSceneId] as ISceneComp;
	const bottomMiddleAnchorId = getAnchorGroupIdAtPositionForScene(scene, IScreenAnchorPositionType.bottomMiddle, componentsById);
	if (!bottomMiddleAnchorId) return snapshotButtonId;
	const {children: entityIds = []} = componentsById[bottomMiddleAnchorId] as IScreenAnchorGroup;

	for (let i = 0; i < entityIds.length; i++) {
		const entity = componentsById[entityIds[i]] as IButton;
		if (entity.type == IComponentType.Button && (entity.subCategory === IButtonSubCategory.snapshot || entity.subCategory === IButtonSubCategory.recording)) {
			snapshotButtonId = entity.id;
		}
	}
	return snapshotButtonId;

}

export const getSnapshotButtonType = (componentsById: { [id: string]: IComponentUnion } | null, entityId: string | null): IButtonSubCategory.snapshot | IButtonSubCategory.recording | null => {
	if (!componentsById || !entityId) return null;
	const entity = componentsById[entityId];
	if (entity.type !== IComponentType.Button) return null;
	if (!entity.subCategory || ![IButtonSubCategory.recording, IButtonSubCategory.snapshot].includes(entity.subCategory)) return null;
	return entity.subCategory;
}

// Given an array of intersections, strips out any non entity intersections ( NB returns IUserData, not a filtered Intersection array )
export const removeNonEntityIntersections = (intersections: Intersection[]) => {
	let userData: IUserData | null = null;
	const contentIds: string[] = [];
	const userDataArray: IUserData[] = [];
	for (let i = 0; i < intersections.length; i++) {
		userData = findParentUserData(intersections[i]);
		// console.log({userData});
		
		if (userData
			&& !userData.contentId.includes('TransformControls')
			&& userData.renderOrder
			&& userData.renderOrder > 0
			&& !contentIds.includes(userData.contentId)
		) {
			userDataArray.push(userData);
			contentIds.push(userData.contentId);
		}
	}
	return userDataArray;
}

export const getIsSceneHasTakePhotoActionEntity = (
	componentsById: {
		[id: string]: IComponentUnion;
	} | null,
	activeSceneId: string | null
): boolean => {
	if (!componentsById || !activeSceneId) return false;
	const scene = componentsById?.[activeSceneId ?? ''] as ISceneComp;
	const faceTrackingLandmarkGroupIds = scene.children.filter((id) => componentsById[id].type === IComponentType.FaceLandmarkGroup);
	const faceTrackedEntities = faceTrackingLandmarkGroupIds.reduce((acc, componentId) => {
		const faceLandmarkGroup = componentsById?.[componentId];
		if (faceLandmarkGroup.type !== IComponentType.FaceLandmarkGroup) return acc;
		acc.push(...faceLandmarkGroup?.children); return acc;
	}, [] as string[]);
	const arContentHasTakePhotoActionEntity = entitiesHaveSomeTakePhotoAction([...scene.children, ...faceTrackedEntities], componentsById);
	const screenContentId = getScreenContentIdForScene(scene, componentsById);
	if (!screenContentId) return arContentHasTakePhotoActionEntity;

	const anchorGroupIds = (componentsById[screenContentId] as IScreenContent).children;
	const srComponentIds = anchorGroupIds.reduce((componentIds, anchorGroupId) => {
		return [...componentIds, ...(componentsById[anchorGroupId] as IScreenAnchorGroup).children];
	}, [] as string[]);
	const srContentHasTakePhotoActionEntity = entitiesHaveSomeTakePhotoAction(srComponentIds, componentsById);
	return srContentHasTakePhotoActionEntity || arContentHasTakePhotoActionEntity;
};

const entitiesHaveSomeTakePhotoAction = (
	componentIds: string[],
	componentsById: {
		[id: string]: IComponentUnion;
	}
) => {
	return componentIds.reduce((hasTakePhotoEntity, componentId) => {
		if (hasTakePhotoEntity) return hasTakePhotoEntity;
		const component = componentsById?.[componentId];
		if (!component || isAbstractComponent(component)) return hasTakePhotoEntity;
		for (const triggerType in component.actions) {
			const actionsArr = component.actions[triggerType as ITriggerTypes];
			if (actionsArr) {
				hasTakePhotoEntity = actionsArr.reduce((isTakePhotoAction, action) => {
					if (action.type === IActionCategory.takePhoto) return true;
					return isTakePhotoAction;
				}, false);
			}
		}
		return hasTakePhotoEntity;
	}, false);
};


export const convertToArray = <T>(a: T | T[]): T[] => {
	return Array.isArray(a) ? a : [a];
}

const color = new Color();
export const convertRgbaToHex = (rgbaArray: ITuple4[], colorSpace: ColorSpace = NoColorSpace): string[] => {
	const hexArray: string[] = [];
	for (let i = 0; i < rgbaArray.length; i++) {
		const rgba = rgbaArray[i];
		color.setRGB(rgba[0] / 255, rgba[1] / 255, rgba[2] / 255, colorSpace);
		hexArray.push(`#${color.getHexString(colorSpace)}`)
	}
	return hexArray;
}

export enum IMode {
	nonar = 'nonar',
	ar = 'ar'
}

export const getAdjustedPosition = (arMode: IMode | undefined, screenRelative: boolean, position: ITuple3): ITuple3 => {
	const p = [...position] as ITuple3;

	if (arMode !== IMode.nonar && !screenRelative) {
		//console.log('halve position');
		return [p[0] / 2, p[1] / 2, p[2] / 2];
	}
	return p;
};

export const getAdjustedScale = (arMode: IMode | undefined, screenRelative: boolean, scale: ITuple3): ITuple3 => {
	// if in non ar mode and not screen relative entity or screen relative entity adjust size
	if ((arMode === IMode.nonar && !screenRelative) || screenRelative) {
		return [scale[0] * 2, scale[1] * 2, scale[2] * 2];
	}
	return scale as ITuple3;
};

export const degToRadTuple= (t: ITuple3): ITuple3 => {
    return [MathUtils.degToRad(t[0]), MathUtils.degToRad(t[1]), MathUtils.degToRad(t[2])];
}

export const getFontJsonUrlByFontFamily = (fontFamily: IFontTypes) => {
	return FONT_TYPES.filter((font) => font.fontFamily === fontFamily)[0].jsonUrl;
}

export const getFontData = (jsonUrl: string): Promise<Font> => {
	const fontLoader = new FontLoader();
	return  new Promise(res => fontLoader.load(jsonUrl, res));
}

export const getBboxScaleByTextGeometry = (geom: TextGeometry) => {
	geom.computeBoundingBox();
	const boundingBox = geom.boundingBox;
	if (!boundingBox) return;
	return boundingBox.getSize(new Vector3());
}

export const getBboxScaleByFont = (text: string, font: Font, size: number, depth: number) => {
	const geom = new TextGeometry(text, { font, size, depth });
	return getBboxScaleByTextGeometry(geom)
}