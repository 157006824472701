import { Environment } from "./specs";
export const localEnvSettings = {
    url: "http://local.my.zap.works:8001/api/v3",
    debug: true
};
export const devEnvSettings = {
    url: "https://dev.my.zap.works/api/v3",
    debug: true
};
export const stagingEnvSettings = {
    url: "https://staging.my.zap.works/api/v3",
    debug: false
};
export const prodEnvSettings = {
    url: "https://my.zap.works/api/v3",
    debug: false
};
export const getSettingsForEnv = (env) => {
    switch (env) {
        case Environment.Local: return localEnvSettings;
        case Environment.Dev: return devEnvSettings;
        case Environment.Staging: return stagingEnvSettings;
        default: return prodEnvSettings;
    }
};
