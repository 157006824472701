import { BaseTokenRequestHandler } from "@openid/appauth/built/token_request_handler";
import { RedirectRequestHandler } from "@openid/appauth/built/redirect_based_handler";
import { LocalStorageBackend } from "@openid/appauth/built/storage";
import { FetchRequestor } from "@openid/appauth/built/xhr";
import { BaseOAuth, CustomQueryStringUtils } from "./base";
const AUTHORIZATION_REQUEST_HANDLE_KEY = 'appauth_current_authorization_request';
export class RedirectOAuth extends BaseOAuth {
    constructor(baseURL, clientId, redirectURI) {
        super(baseURL, clientId);
        this.redirectURI = redirectURI;
        this.storage = new LocalStorageBackend();
        this._authorizationHandler = new RedirectRequestHandler(this.storage, new CustomQueryStringUtils());
        this._tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
        // set notifier to deliver responses
        this._authorizationHandler.setAuthorizationNotifier(this._notifier);
        // set a listener to listen for authorization responses
        this._notifier.setAuthorizationListener((request, response, error) => {
            this._log('Authorization request complete ', request, response, error);
            if (response) {
                this._request = request;
                this._code = response.code;
            }
        });
    }
    cleanupStorage() {
        // https://github.com/openid/AppAuth-JS/blob/c30f85e490ab41c9f1e8f8ee05bfdfe964e08626/src/redirect_based_handler.ts#L124-L128
        const reqKey = this.storage.getItem(AUTHORIZATION_REQUEST_HANDLE_KEY);
        return Promise.all([
            this.storage.removeItem(AUTHORIZATION_REQUEST_HANDLE_KEY),
            this.storage.removeItem(`${reqKey}_appauth_authorization_request`),
            this.storage.removeItem(`${reqKey}_appauth_authorization_service_configuration`)
        ]);
    }
}
