export var IProgressStatus;
(function (IProgressStatus) {
    IProgressStatus["uploading"] = "uploading";
    IProgressStatus["processing"] = "processing";
    IProgressStatus["analysing"] = "analysing";
    IProgressStatus["training"] = "training";
    IProgressStatus["completed"] = "completed";
    IProgressStatus["trainingCompleted"] = "trainingCompleted";
    IProgressStatus["imgQualityError"] = "imgQualityError";
    IProgressStatus["error"] = "error";
})(IProgressStatus || (IProgressStatus = {}));
