export var IFileType;
(function (IFileType) {
    IFileType["Image"] = "image";
    IFileType["Video"] = "video";
    IFileType["StreamingVideo"] = "streaming video";
    IFileType["Audio"] = "sound";
    IFileType["YouTubeThumbnail"] = "youtube thumbnail";
    IFileType["VimeoThumbnail"] = "vimeo thumbnail";
    IFileType["WebsiteScreenshot"] = "website screenshot";
    IFileType["TrackingImage"] = "tracking image";
    IFileType["ZPP"] = "zpp";
    IFileType["UARPackage"] = "uarzip";
    IFileType["LookUpImage"] = "lookup image";
    IFileType["Model3D"] = "3d model";
    IFileType["Image360"] = "360 image";
    IFileType["Video360"] = "360 video";
    IFileType["Temp"] = "temporary";
    IFileType["ZPT"] = "zpt";
})(IFileType || (IFileType = {}));
export var IFileStatus;
(function (IFileStatus) {
    IFileStatus["Pending"] = "pending";
    IFileStatus["Ready"] = "ready";
    IFileStatus["Error"] = "error";
    IFileStatus["Deleted"] = "deleted";
    IFileStatus["Success"] = "success"; // v2
})(IFileStatus || (IFileStatus = {}));
