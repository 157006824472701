import { ThreeEvent } from '@react-three/fiber';
import * as Sentry from '@sentry/react';
import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { ITuple3, ITuple4 } from '../../component-data-structure';
import BrokenIcon from './BrokenIcon/BrokenIcon';
import PlaceholderEntity from './PlaceholderEntity';

interface IParentProps {
	scale: ITuple3;
	rotation: ITuple3;
	position?: ITuple3;
	renderOrder: number;
	children?: ReactNode;
	onError?: (error: Error, componentStack: string, eventId: string) => unknown;
	onPointerUp?: (e: ThreeEvent<PointerEvent>) => unknown;
	onPointerDown?: (e: ThreeEvent<PointerEvent>) => unknown;
}

const ErrorBoundary: FunctionComponent<IParentProps> = ({ scale, rotation, position = [0, 0, 0], renderOrder, children, onPointerUp, onPointerDown, onError }) => {
	const memo = useMemo(
		() => ({
			scale,
			rotation,
			position,
			fillRgba: [227, 227, 227, 1] as ITuple4,
			borderRgba: [181, 181, 181, 1] as ITuple4,
		}),
		[scale, rotation, position]
	);

	return (
		<Sentry.ErrorBoundary
			fallback={
				<PlaceholderEntity
					rotation={memo.rotation}
					fillRgba={memo.fillRgba}
					scale={memo.scale}
					position={memo.position}
					renderOrder={renderOrder}
					onPointerDown={onPointerDown || undefined}
					onPointerUp={onPointerUp || undefined}
				>
					<BrokenIcon scale={memo.scale} renderOrder={renderOrder + 2} />
				</PlaceholderEntity>
			}
			onError={(e, compStack, eventId) => {
				onError?.(e, compStack, eventId);
			}}
		>
			{children}
		</Sentry.ErrorBoundary>
	);
};

export default ErrorBoundary;