import { ThreeEvent } from '@react-three/fiber';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Vector3 } from 'three';
import { IDesignerState, IDomIdSelectors } from '../../../../typings';
import { onSetMultipleComponentProps_Cn_Doc } from '../../../store/actions';
import FloatingInput from '../FloatingInput/FloatingInput';
import SelectedEntityPositionHotspot from '../Hotspots/SelectedEntityPositionHotspot';
import { IText3d, IText3dReactProps, ITuple3 } from '../r3f-components/component-data-structure';
import { getUserDataFromIntersections, isHighestRenderOrder } from '../r3f-components/utils/general';
import EntityBoundaries from './EntityBoundaries/EntityBoundaries';
import { useGetHocSpatialProperties, useGetHocTextProperties, useHocPointerDown, useHocPointerMove, useHocPointerUp } from './hocCustomHooks';
import { getEntityRenderOrder } from './hocUtils';

interface IText3dWrapped {
	id: string;
	enabled?: boolean;
}

const withText3dBehaviour = (WrappedText3d: FunctionComponent<IText3dReactProps>): FunctionComponent<IText3dWrapped> => {
	const FuncComp: FunctionComponent<IText3dWrapped> = ({ enabled: entityIsEnabled = true, id }) => {
		const dispatch = useDispatch();
		// Selectors
		const showFloatingInput = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds?.length);
		const entity = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById[id] as IText3d);
		const projectShadowsEnabled = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.shadowsEnabled ?? true);

		// Refs
		const pointerStageRef = useRef<'down' | 'move' | 'up' | null>(null);
		const shiftLockedRef = useRef<number | null>(null);
		const initialPosRef = useRef<ITuple3 | null>(null); // Used to calculate pointer offset in pointerMove
		const previousPositionRef = useRef<ITuple3 | null>(null); // The previous position, used to compare with the current position
		const pointerUpHasRunRef = useRef<boolean | null>(null); // To prevent multiple dispatched actions when pointerUp runs twice this is reset onPointerMove
		const canvasRef = useRef<HTMLCanvasElement>(document.getElementById(IDomIdSelectors.zapparCanvas) as HTMLCanvasElement);
		const [doubleClicked, setDoubleClicked] = useState(false); // For text / button entities, to display FloatingInput
		const [showEntityPositionHotspot, setShowEntityPositionHotspot] = useState(false);

		const textGeometryScaleRef = useRef<Vector3>();

		// Entity properties to pass to wrapped component
		const renderOrder = getEntityRenderOrder(entity);
		const { opacity, text, fontFamily, fontSize, textAlignment, castShadow = false, receiveShadow = false } = entity;
		const { scale, position, rotation } = useGetHocSpatialProperties(entity);
		const { fontRgba } = useGetHocTextProperties(entity);

		// Pointer events
		const onPointerDown = useHocPointerDown({ pointerStageRef, initialPosRef, entity, setShowEntityPositionHotspot });
		const onPointerMove = useHocPointerMove({ pointerStageRef, initialPosRef, pointerUpHasRunRef, previousPositionRef, entity, shiftLockedRef });
		const onPointerUp = useHocPointerUp({ pointerStageRef, pointerUpHasRunRef, initialPosRef, entity, setShowEntityPositionHotspot, shiftLockedRef });
		const onPointerOver = () => (canvasRef.current.style.cursor = 'text');
		const onPointerOut = () => (canvasRef.current.style.cursor = 'default');
		const onDoubleClick = (e: ThreeEvent<MouseEvent>) => {
			if (!isHighestRenderOrder(getUserDataFromIntersections(e.intersections), renderOrder)) return;
			setDoubleClicked(true);
		};

		const onTextGeometryChange = (scale: Vector3) => {
			if (!textGeometryScaleRef.current) {
				textGeometryScaleRef.current = scale;
				if (!entity.origScale) {
					dispatch(onSetMultipleComponentProps_Cn_Doc([{
						id,
						origScale: [scale.x, scale.y, entity.depth]
					}]))
				}
				return;
			}
			
			const xRatio = (scale.x / textGeometryScaleRef.current.x);
			const yRatio = 1;
			textGeometryScaleRef.current = scale;

			if (xRatio !== 1 || yRatio !== 1) {
				dispatch(onSetMultipleComponentProps_Cn_Doc([{
					id,
					scale: [entity.scale[0] * xRatio, entity.scale[1] * yRatio, entity.scale[2]]
				}]))
			}
		}

		const floatingInput =
			showFloatingInput && doubleClicked ? (
				<FloatingInput position={position} text={entity.text} onBlur={() => setDoubleClicked(false)} onUnmount={() => setDoubleClicked(false)} />
			) : undefined;

		return (
			<>
				<EntityBoundaries
					position={position}
					rotation={rotation}
					scale={scale}
					renderOrder={renderOrder}
					onPointerDown={entityIsEnabled ? onPointerDown : undefined}
					onPointerUp={onPointerUp}
					label={`Text component ${id} render order ${renderOrder}`}
					floatingInput={floatingInput}
				>
					<WrappedText3d
						id={id}
						scale={scale}
						rotation={rotation}
						position={position}
						renderOrder={renderOrder}
						opacity={opacity}
						origScale={entity.origScale}
						textAlignment={textAlignment}
						fontFamily={fontFamily}
						fontSize={fontSize}
						text={text}
						fontRgba={fontRgba}
						depth={entity.depth}
						curveSegments={entity.curveSegments}
						bevelEnabled={entity.bevelEnabled}
						material={entity.material}
						bevelThickness={entity.bevelThickness}
						bevelSize={entity.bevelSize}
						bevelOffset={entity.bevelOffset}
						bevelSegments={entity.bevelSegments}
						castShadow={castShadow && projectShadowsEnabled}
						receiveShadow={receiveShadow && projectShadowsEnabled}
						onPointerDown={entityIsEnabled ? onPointerDown : undefined}
						onPointerUp={entityIsEnabled ? onPointerUp : undefined}
						onPointerOver={onPointerOver}
						onPointerOut={onPointerOut}
						onDoubleClick={onDoubleClick}
						onTextGeometryChange={onTextGeometryChange}
					/>
				</EntityBoundaries>
				{showEntityPositionHotspot && <SelectedEntityPositionHotspot onPointerMove={showEntityPositionHotspot ? onPointerMove : undefined} onPointerUp={entityIsEnabled ? onPointerUp : undefined} />}
			</>
		);
	};
	return FuncComp;
};

export default withText3dBehaviour;
