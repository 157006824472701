import { Environment, OAuthGrantType, PROJECT_TEMPLATE_CATEGORIES } from "../specs";
import { getSettingsForEnv, prodEnvSettings } from "../settings";
import { ZmlClient } from '../zml/client';
export class BaseClient {
    constructor(settings, oauth) {
        this.env = Environment.Prod;
        this.grantType = OAuthGrantType.AuthorizationCode;
        this.debug = false;
        this.envSettings = prodEnvSettings;
        this._oauth = oauth;
        this.config(settings);
        this._oauth.setServiceConfig(this.envSettings.url);
        this.zml = new ZmlClient(this);
    }
    config(settings) {
        this.env = typeof settings.env === "string" ? settings.env : this.env;
        this.grantType = typeof settings.grantType === "string" ? settings.grantType : this.grantType;
        this.envSettings = getSettingsForEnv(this.env);
        this.debug = typeof settings.debug === "boolean" ? settings.debug : this.envSettings.debug;
    }
    makeAuthorizationRequest(scope = '', state) {
        this._oauth.makeAuthorizationRequest(scope, state);
    }
    checkForAuthorizationResponse() {
        return this._oauth.checkForAuthorizationResponse();
    }
    setAccessToken(t) {
        this._accessToken = t;
    }
    getAccessToken() {
        return this._accessToken ? this._accessToken : this._oauth.getAccessToken();
    }
    makeTokenRequest() {
        return this._oauth.makeTokenRequest();
    }
    requestWithProgress(url, body, progressCb, requestId = 0) {
        console.error('NOT IMPLEMENTED');
        return;
    }
    async _authenticationSetup() {
        let token = this.getAccessToken();
        if (typeof token === "undefined") {
            this._oauth.makeAuthorizationRequest();
            token = await this._oauth.waitForAccessToken();
        }
        return {
            method: "GET",
            headers: {
                'content-type': 'application/json; charset=utf-8',
                authorization: `Bearer ${token}`
            },
            mode: "cors",
            credentials: "omit"
        };
    }
    cleanupStorage() {
        return this._oauth.cleanupStorage();
    }
    async getAPISVG(endpoint) {
        const options = await this._authenticationSetup();
        const resp = await fetch(`${this.envSettings.url}${endpoint}`, options);
        if (resp.ok)
            return await resp.blob();
        throw new Error(`${resp.status} ${await resp.text()}`);
    }
    async apiRequest(endpoint, postData, method) {
        let options = await this._authenticationSetup();
        if (typeof postData !== "undefined") {
            options.body = JSON.stringify(postData);
            options.method = method;
            if (typeof method === "undefined") {
                options.method = "POST";
            }
        }
        const req = await fetch(`${this.envSettings.url}${endpoint}`, options);
        let resp;
        if (req.status === 204)
            return resp; // no content (e.g. for DELETE requests)
        if (req.ok)
            return await req.json();
        throw new Error(`${req.status} ${await req.text()}`);
    }
    getUser() {
        return this.apiRequest('/user/');
    }
    getWorkspaces() {
        return this.apiRequest('/workspaces/');
    }
    createProject(tool) {
        return this.apiRequest('/projects/', { tool });
    }
    getProject(id) {
        return this.apiRequest(`/projects/${id}/`);
    }
    renameProject(projectId, title) {
        return this.apiRequest(`/projects/${projectId}/`, { title }, 'PATCH');
    }
    preview(projectId, data) {
        return this.apiRequest(`/projects/${projectId}/preview/`, data);
    }
    publish(projectId, data) {
        return this.apiRequest(`/projects/${projectId}/publish/`, data);
    }
    getTriggerInfo(projectId) {
        return this.apiRequest(`/projects/${projectId}/triggers/`);
    }
    createProjectVersion(projectId, data) {
        return this.apiRequest(`/projects/${projectId}/uar-version/`, data);
    }
    getProjectVersions(projectId) {
        return this.apiRequest(`/projects/${projectId}/versions/`);
    }
    getProjectTemplates(toolKey, categories = PROJECT_TEMPLATE_CATEGORIES, page = 1, limit = 12) {
        if (typeof toolKey === 'undefined') {
            return this.apiRequest(`/project-templates/`);
        }
        const qs = new URLSearchParams({
            tool: toolKey,
            page: page.toString(),
            limit: limit.toString()
        });
        for (const cat of categories) {
            qs.append('cat', cat);
        }
        return this.apiRequest(`/project-templates/?${qs}`);
    }
    getDesignerTemplates(options) {
        return this.getProjectTemplates('designer-2', options.categories, options.page, options.limit);
    }
    getProjectFeatures(projectId) {
        return this.apiRequest(`/projects/${projectId}/features/`);
    }
    async postProjectScreenshot(projectId, image) {
        const form = new FormData();
        form.append('image', image);
        const options = {
            method: 'PUT',
            headers: {
                authorization: `Bearer ${this.getAccessToken()}`
            },
            body: form,
            keepalive: true
        };
        return await fetch(`${this.envSettings.url}/projects/${projectId}/screenshot/`, options);
    }
}
