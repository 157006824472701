import { Dispatch } from 'redux';
import { IFileImage, IFileModel3D, IFileStatus, IFileStreamingVideo, IFileType, ISearchResponse } from '~/../zw-api-client/src/zml';
import {
	IActiveEditingActionData,
	IActivePresetAnimationState,
	IAudio,
	IAudioStatus, IBackgroundContent, IBaseToast, ICanvasName, IChromaKeyEditModalData, IDesignerState, IDragState, IEntityDrawerTypes, IEntityShelves, ILoadedComponentUnion, IMediaLibraryDisplay, IModal, IOpenEntityCategoryDrawerDict, IProjectData, IProjectShelves,
	ISceneShelves,
	IScreenRelativeDevice,
	IScreenRelativeDeviceDimensions, ISelectedTemplateProject, ITransformControlsMode, IUploadInfo
} from '../../../typings';
import {
	IActionCategory, IBkgrSound, IButtonCategory, IButtonSubCategory, IChromaKey, IComponentType, IContentDoc, IEmitter, IEmitterGroupSettings, IEmitterSettings, IFaceLandmark, IFontTypes, IModel3dAnimation, IModel3dAnimationOptions, INumberDict, IProjectColor, IScreenAnchorPositionType, ISpatialComponentActionData, ISpatialComponentUnion, ITextAlignment, ITrackingImageData, ITrackingTypes, ITransitionCategoryTypes, ITransitionEffectTypes, ITriggerTypes, ITuple3, IVector2,
	IVector3, IVector3Dict, IVector4, IVector4Dict, IZmlComponentUnion
} from '../../components/r3f/r3f-components/component-data-structure';
import { IAnalyticsTrackingDict } from '../../components/r3f/r3f-components/component-data-structure/types/analytics';
import { ITargetImageReferenceObjectData } from '../../components/r3f/r3f-components/component-data-structure/types/targetImageReferenceObject';
import { IWebEmbedPreviewSettings, IWebEmbedSettings } from '../../components/r3f/r3f-components/component-data-structure/types/webEmbed';
import { ISyncDocUser } from '../../sync/zapworks';
import { IBounds } from '../../utils/transforms';
import { IDeviceType } from '../../components/r3f/r3f-components/component-data-structure/types/device';
import { IBackground360 } from '../../components/r3f/r3f-components/component-data-structure/types/background360';
import { IVideoProgressData } from '../../components/r3f/r3f-components/component-data-structure/types/videoStatus';

export enum IContentDocActionTypes {
	SET_INITIAL_TEMPLATE_SLUG = 'SET_INITIAL_TEMPLATE_SLUG',
	REMOVE_IDLE_ANIMATION = 'REMOVE_IDLE_ANIMATION',
	REMOVE_IDLE_POSE_TIME = 'REMOVE_IDLE_POSE_TIME',
	SET_ANALYTICS_TRACKING = 'SET_ANALYTICS_TRACKING',
	CLEAR_SCREEN_SCENE_CONTENT = 'CLEAR_SCREEN_SCENE_CONTENT',
	COPY_SCREEN_SCENE_CONTENT = 'COPY_SCREEN_SCENE_CONTENT',
	SET_WEB_EMBED_CN_DOC = 'SET_WEB_EMBED_CN_DOC',
	REPLACE_ENTITY_CN_DOC = 'REPLACE_ENTITY_CN_DOC',
	ADD_BUTTON_CN_DOC = 'ADD_BUTTON_CN_DOC',
	ADD_TEXT_CN_DOC = 'ADD_TEXT_CN_DOC',
	ADD_ZML_ENTITY_CN_DOC = 'ADD_ZML_ENTITY_CN_DOC',
	ADD_ENTITY_CN_DOC = 'ADD_ENTITY_CN_DOC',
	ADD_SCENES_AT_INDEX_CN_DOC = 'ADD_SCENES_AT_INDEX_CN_DOC',
	COPY_SCENES_AT_INDEX_CN_DOC = 'COPY_SCENES_AT_INDEX_CN_DOC',
	LOCK_ENTITY_CN_DOC = 'LOCK_ENTITY_CN_DOC',
	SET_ENTITY_POSITIONS_CN_DOC = 'SET_ENTITY_POSITIONS_CN_DOC',
	SET_ENTITY_ROTATIONS_CN_DOC = 'SET_ENTITY_ROTATIONS_CN_DOC',
	SET_ENTITY_COLORS_CN_DOC = 'SET_ENTITY_COLORS_CN_DOC',
	SET_SCALE_FACTORS_CN_DOC = 'SET_SCALE_FACTORS_CN_DOC',
	PASTE_COPIED_ENTITIES_CN_DOC = 'PASTE_COPIED_ENTITIES_CN_DOC',
	REMOVE_ENTITIES_CN_DOC = 'REMOVE_ENTITIES_CN_DOC',
	CHANGE_CN_DOC_REDUX = 'CHANGE_CN_DOC_REDUX',
	SET_COMPONENT_TRANSITION_CN_DOC = 'SET_COMPONENT_TRANSITION_CN_DOC',
	SET_COMPONENT_ACTION_CN_DOC = 'SET_COMPONENT_ACTION_CN_DOC',
	REMOVE_SCENE_REFERENCES_CN_DOC = 'REMOVE_SCENE_REFERENCES_CN_DOC',
	SET_MULTIPLE_COMPONENT_PROPS_CN_DOC = 'SET_MULTIPLE_COMPONENT_PROPS_CN_DOC',
	SET_MULTIPLE_COMPONENT_PROPS_CN_DOC_TEST = 'SET_MULTIPLE_COMPONENT_PROPS_CN_DOC_TEST',
	ADD_IMAGE_TRACKING_CN_DOC = 'ADD_IMAGE_TRACKING_CN_DOC',
	SET_ACTIVE_SCENE_CHILDREN = 'SET_ACTIVE_SCENE_CHILDREN',
	UNDO_CN_DOC = 'UNDO_CN_DOC',
	REDO_CN_DOC = 'REDO_CN_DOC',
	REMOVE_IMAGE_TRACKING_CN_DOC = 'REMOVE_IMAGE_TRACKING_CN_DOC',
	SET_ROOT_CHILDREN = 'SET_ROOT_CHILDREN',
	ADD_COMPONENT_ACTION_CN_DOC = 'ADD_COMPONENT_ACTION_CN_DOC',
	REMOVE_COMPONENT_ACTION_CN_DOC = 'REMOVE_COMPONENT_ACTION_CN_DOC',
	REPARENT_COMPONENT_CN_DOC = 'REPARENT_COMPONENT_CN_DOC',
	ADD_FACE_LANDMARK_TO_SCENE_CN_DOC = 'ADD_FACE_LANDMARK_TO_SCENE_CN_DOC',
	ADD_ANCHOR_GROUP_TO_SCENE_CN_DOC = 'ADD_ANCHOR_GROUP_TO_SCENE_CN_DOC',
	ADD_PROJECT_COLORS_CN_DOC = 'ADD_PROJECT_COLORS_CN_DOC',
	SET_EMITTER_PROPERTIES_CN_DOC = 'SET_EMITTER_PROPERTIES_CN_DOC',
	SET_EMITTER_GROUP_PROPERTIES_CN_DOC = 'SET_EMITTER_GROUP_PROPERTIES_CN_DOC',
	SET_EMITTER_BASE_PROPERTIES_CN_DOC = 'SET_EMITTER_BASE_PROPERTIES_CN_DOC',
	SET_TARGET_IMAGE_REFERENCE_OBJECT_CN_DOC = 'SET_TARGET_IMAGE_REFERENCE_OBJECT_CN_DOC',
	SET_CHROMA_KEY_PROPERTIES_CN_DOC = 'SET_CHROMA_KEY_PROPERTIES_CN_DOC',
	SET_PROJECT_SHADOWS_ENABLED_CN_DOC = 'SET_PROJECT_SHADOWS_ENABLED_CN_DOC',
	SET_ACTION_TRIGGER_CN_DOC = 'SET_ACTION_TRIGGER_CN_DOC',
	SET_DISABLE_HLS_VIDEO_STREAMING_CN_DOC = 'SET_DISABLE_HLS_VIDEO_STREAMING_CN_DOC',
	SET_DEVICE_CN_DOC = 'SET_DEVICE_CN_DOC',
	SET_SCENE_360_BACKGROUND = 'SET_SCENE_360_BACKGROUND',
	SET_SCENE_360_INITIAL_CAMERA_ROTATION = 'SET_SCENE_360_INITIAL_CAMERA_ROTATION',
	SET_VIDEO_RENDITION_PROGRESS_DATA = 'SET_VIDEO_RENDITION_PROGRESS_DATA'
}

export enum IUserActionTypes {
	SET_ACTIVE_EDITING_ACTION_DATA = 'SET_ACTIVE_EDITING_ACTION_DATA',
	SET_POPOUT_IDEAL_TOP_POSITION = 'SET_POPOUT_IDEAL_TOP_POSITION',
	SET_MULTIPLE_ENTITY_SELECT_BOUNDARY = 'SET_MULTIPLE_ENTITY_SELECT_BOUNDARY',
	SET_CHROMA_KEY_EDIT_MODAL_DATA = 'SET_CHROMA_KEY_EDIT_MODAL_DATA',
	SET_SELECTED_TEMPLATE_PROJECT = 'SET_SELECTED_TEMPLATE_PROJECT',
	SET_ACTIVE_ANIMATION_PRESET =  'SET_ACTIVE_ANIMATION_PRESET',
	SET_TARGET_IMAGE_REFERENCE_OBJECT_POSITION = 'SET_TARGET_IMAGE_REFERENCE_OBJECT_POSITION',
	SET_CURVATURES = 'SET_CURVATURES',
	SET_FILL_RGBA = 'SET_FILL_RGBA',
	SET_FONT_RGBA = 'SET_FONT_RGBA',
	SET_BORDER_RGBA = 'SET_BORDER_RGBA',
	SET_EMITTER_AREA_SCALES = 'SET_EMITTER_AREA_SCALES',
	OPEN_MODAL = 'OPEN_MODAL',
	CLOSE_MODAL = 'CLOSE_MODAL',
	SET_OPEN_SCENE_MENU_DRAWER = 'SET_OPEN_SCENE_MENU_DRAWER',
	SET_OPEN_PROJECT_MENU_DRAWER = 'SET_OPEN_PROJECT_MENU_DRAWER',
	SET_WEB_EMBED_PREVIEW_SETTINGS = 'SET_WEB_EMBED_PREVIEW_SETTINGS',
	SET_WEB_EMBED_EDITING_MODE = 'SET_WEB_EMBED_EDITING_MODE',
	SET_MEDIA_LIBRARY_AUDIO = 'SET_MEDIA_LIBRARY_AUDIO',
	ADD_TO_LOADED_AUDIO = 'ADD_TO_LOADED_AUDIO',
	SET_REPLACE_ENTITY_MODE = 'SET_REPLACE_ENTITY_MODE',
	SET_FILE_VERIFYING = 'SET_FILE_VERIFYING',
	SET_OPEN_ENTITY_MENU_CATEGORY_DRAWER = 'SET_OPEN_ENTITY_MENU_CATEGORY_DRAWER',
	SET_OPEN_ENTITY_MENU_DRAWER = 'SET_OPEN_ENTITY_MENU_DRAWER',
	SET_OPEN_INSPECTOR_MENU_ENTITY_SHELF = 'SET_OPEN_INSPECTOR_MENU_ENTITY_SHELF',
	SETUP_AUTOMERGE_SOCKET = 'SETUP_AUTOMERGE_SOCKET',
	CANVAS_IS_LOADED = 'CANVAS_IS_LOADED',
	SET_USER_ID = 'SET_USER_ID',
	UPDATE_USERS = 'UPDATE_USERS',
	SET_ACTIVE_SCENE = 'SET_ACTIVE_SCENE',
	SET_START_POS = 'SET_START_POS',
	GET_ALL_IMAGES = 'GET_ALL_IMAGES',
	SET_MARKER_INDEX_PRESSED = 'SET_MARKER_INDEX_PRESSED',
	SET_POSITIONING_ACTIVE = 'SET_POSITIONING_ACTIVE',
	SET_BACKGROUND_HOTSPOT_ENABLED = 'SET_BACKGROUND_HOTSPOT_ENABLED',
	SET_SCALE_HOTSPOT_ENABLED = 'SET_SCALE_HOTSPOT_ENABLED',
	SET_ROTATION_ACTIVE = 'SET_ROTATION_ACTIVE',
	SET_CLICK_OFFSET = 'SET_CLICK_OFFSET',
	SET_HOTSPOT_POINTER_MOVED = 'SET_HOTSPOT_POINTER_MOVED',
	SET_CURRENT_SCENE_INDEX = 'SET_CURRENT_SCENE_INDEX',
	SCROLL_SCENE_MENU = 'SCROLL_SCENE_MENU',
	SET_SCENE_SNAPSHOTS = 'SET_SCENE_SNAPSHOTS',
	ADD_ENTITY_IDS_TO_SELECTION = 'ADD_ENTITY_IDS_TO_SELECTION',
	REMOVE_SELECTION = 'REMOVE_SELECTION',
	REMOVE_ENTITIES = 'REMOVE_ENTITIES',
	REMOVE_LOCAL_USER_SELECTION = 'REMOVE_LOCAL_USER_SELECTION',
	CHANGE_ZOOM_LEVEL = 'CHANGE_ZOOM_LEVEL',
	DISABLE_REDO_BTN = 'DISABLE_REDO_BTN',
	DISABLE_UNDO_BTN = 'DISABLE_UNDO_BTN',
	SET_HOTKEY = 'SET_HOTKEY',
	REMOVE_HOTKEY = 'REMOVE_HOTKEY',
	SET_DRAG_SELECTION_ENABLED = 'SET_DRAG_SELECTION_ENABLED',
	SET_GROUP_INVERSION = 'SET_GROUP_INVERSION',
	COPY_SELECTED_ENTITIES = 'COPY_SELECTED_ENTITIES',
	SET_SELECTION_2D_POSITION = 'SET_SELECTION_2D_POSITION',
	SET_SELECTION_2D_TOP_LEFT_POSITION = 'SET_SELECTION_2D_TOP_LEFT_POSITION',
	SET_SELECTION_2D_TOP_RIGHT_POSITION = 'SET_SELECTION_2D_TOP_RIGHT_POSITION',
	SET_SELECTION_2D_BOTTOM_LEFT_POSITION = 'SET_SELECTION_2D_BOTTOM_LEFT_POSITION',
	SET_SELECTION_2D_BOTTOM_RIGHT_POSITION = 'SET_SELECTION_2D_BOTTOM_RIGHT_POSITION',
	SET_ROTATION_MARKER_2D_POSITION = 'SET_ROTATION_MARKER_2D_POSITION',
	SET_SCENE_ENTITY_LIST_VISIBLE = 'SET_SCENE_ENTITY_LIST_VISIBLE',
	SET_SNAPSHOT_DICT = 'SET_SNAPSHOT_DICT',
	SET_SCENE_CAROUSEL_SCROLL_LEFT = 'SET_SCENE_CAROUSEL_SCROLL_LEFT',
	SET_SCENE_MENU_LARGE = 'SET_SCENE_MENU_LARGE',
	FOCUS_SCENE_NAME_INPUT = 'FOCUS_SCENE_NAME_INPUT',
	SET_PROJECT_INFO = 'SET_PROJECT_INFO',
	SET_ENTITY_MENU_DRAG_ACTIVE = 'SET_ENTITY_MENU_DRAG_ACTIVE',
	SET_ENTITY_MENU_DRAG_HOTSPOT_POSITION = 'SET_ENTITY_MENU_DRAG_HOTSPOT_POSITION',
	SET_ENTITY_MENU_DRAG_INFO = 'SET_ENTITY_MENU_DRAG_INFO',
	SET_LOADED_AUDIO = 'SET_LOADED_AUDIO',
	SET_LOADED_360_BACKGROUND = 'SET_LOADED_360_BACKGROUND',
	LOADING_MEDIA = 'LOADING_MEDIA',
	ADD_TO_LOADED_MEDIA = 'ADD_TO_LOADED_MEDIA',
	SET_AUDIO_STATUS = 'SET_AUDIO_STATUS',
	SET_CURRENT_UPLOADS = 'SET_CURRENT_UPLOADS',
	REMOVE_UPLOAD = 'REMOVE_UPLOAD',
	REMOVE_LOADED_MEDIA = 'REMOVE_LOADED_MEDIA',
	SET_TRACKING_IMG_DATA = 'SET_TRACKING_IMG_DATA',
	SET_TRACKING_IMG_UPLOADING = 'SET_TRACKING_IMG_UPLOADING',
	REMOVE_TRACKING_IMG_DATA = 'REMOVE_TRACKING_IMG_DATA',
	SET_PURE_REACT_IN_CANVAS_HOVERED = 'SET_PURE_REACT_IN_CANVAS_HOVERED',
	SET_SOCKET_CONNECTED = 'SET_SOCKET_CONNECTED',
	SET_PROJECT_LOADING_PROGRESS = 'SET_PROJECT_LOADING_PROGRESS',
	SET_PROJECT_LOADING_FAILURE = 'SET_PROJECT_LOADING_FAILURE',
	ADD_TOAST = 'ADD_TOAST',
	REMOVE_TOAST = 'REMOVE_TOAST',
	SET_SELECTED_ENTIY_IDS = 'SET_SELECTED_ENTIY_IDS',
	EDIT_SCENE_TITLE = 'EDIT_SCENE_TITLE',
	SET_3D_MODE = 'SET_3D_MODE',
	SET_SCREEN_RELATIVE_MODE = 'SET_SCREEN_RELATIVE_MODE',
	SET_TRANSFORM_CONTROLS_ACTIVE = 'SET_TRANSFORM_CONTROLS_ACTIVE',
	SET_TRANSFORM_CONTROLS_MODE = 'SET_TRANSFORM_CONTROLS_MODE',
	SET_SCREEN_RELATIVE_DEVICE = 'SET_SCREEN_RELATIVE_DEVICE',
	SET_SCREEN_RELATIVE_DEVICE_PREVIOUS_CUSTOM_DIMENSIONS = 'SET_SCREEN_RELATIVE_DEVICE_PREVIOUS_CUSTOM_DIMENSIONS',
	SET_MEDIA_LIBRARY_DISPLAY = 'SET_MEDIA_LIBRARY_DISPLAY',
	SET_TEMP_ANIMATE_MODEL_ACTION = 'SET_TEMP_ANIMATE_MODEL_ACTION',
	SET_VISIBLE_CANVAS = 'SET_VISIBLE_CANVAS',
	SET_POSITIONS = 'SET_POSITIONS',
	SET_SCALES = 'SET_SCALES',
	SET_ROTATIONS = 'SET_ROTATIONS',
	SET_ACTIVE_FACE_LANDMARK = 'SET_ACTIVE_FACE_LANDMARK',
	SET_SHOW_FACE_LANDMARKS = 'SET_SHOW_FACE_LANDMARKS',
	SET_HOVERED_FACE_LANDMARK_DROPDOWN = 'SET_HOVERED_FACE_LANDMARK_DROPDOWN',
	SET_EMITTER_IS_STATIC = 'SET_EMITTER_IS_STATIC',
	SET_PREVIEW_CODE_SRC = 'SET_PREVIEW_CODE_SRC',
	SET_PREVIEW_URL = 'SET_PREVIEW_URL',
	SET_DRAG_CONTROLS_UPDATE_TRIGGER = 'SET_DRAG_CONTROLS_UPDATE_TRIGGER',
	SET_TEMP_INITIAL_ROTATION = 'SET_TEMP_INITIAL_ROTATION',
	SET_VIDEO_RENDITION_PROGRESS = 'SET_VIDEO_RENDITION_PROGRESS',
	SET_SHOW_PUBLISH_POPUP = 'SET_SHOW_PUBLISH_POPUP',
	SET_PUBLISH_TRIGGER = 'SET_PUBLISH_TRIGGER'
}

///////////////////
// Purely Redux related actions (not affecting automerge)
///////////////////


export interface IOnSetShowPublishPopup_Action {
	type: IUserActionTypes.SET_SHOW_PUBLISH_POPUP,
	payload: boolean
}

export interface IOnSetShowPublishPopup {
	(payload: boolean): IOnSetShowPublishPopup_Action
}

export interface IOnSetPublishTrigger_Action {
	type: IUserActionTypes.SET_PUBLISH_TRIGGER,
	payload: number
}

export interface IOnSetPublishTrigger {
	(payload: number): IOnSetPublishTrigger_Action
}


export interface IOnSetVideoProgress_Action {
	type: IUserActionTypes.SET_VIDEO_RENDITION_PROGRESS;
	payload: {id: string; progress: number | null};
}

export interface IOnSetVideoProgress {
	(payload: {id: string; progress: number | null}): IOnSetVideoProgress_Action;
}

export interface IOnSetActiveEditingActionData_Action {
	type: IUserActionTypes.SET_ACTIVE_EDITING_ACTION_DATA;
	payload: IActiveEditingActionData | null;
}

export interface IOnSetActiveAnimationPresetData_Action {
	type: IUserActionTypes.SET_ACTIVE_ANIMATION_PRESET;
	payload: boolean;
}

export interface IOnSetActiveAnimationPreset {
	(payload: boolean): IOnSetActiveAnimationPresetData_Action;
}

export interface IOnSetSelectedTemplateProject_Action {
	type: IUserActionTypes.SET_SELECTED_TEMPLATE_PROJECT,
	payload: ISelectedTemplateProject
}

export interface IOnSetPreviewCodeSrc_Action {
	type: IUserActionTypes.SET_PREVIEW_CODE_SRC,
	payload: string
}

export interface IOnSetPreviewCodeSrc {
	(payload: string): IOnSetPreviewCodeSrc_Action;
}

export interface IOnSetPreviewUrl_Action {
	type: IUserActionTypes.SET_PREVIEW_URL,
	payload: string
}

export interface IOnSetPreviewUrl {
	(payload: string): IOnSetPreviewUrl_Action;
}

export interface IOnSetSelectedTemplateProjectId {
	(payload: ISelectedTemplateProject): IOnSetSelectedTemplateProject_Action;
}

export interface IOnSetTargetReferenceObjectPosition_Action {
	type: IUserActionTypes.SET_TARGET_IMAGE_REFERENCE_OBJECT_POSITION;
	payload: number | null;
}

export interface IOnSetPopOutIdealTopPosition_Action {
	type: IUserActionTypes.SET_POPOUT_IDEAL_TOP_POSITION;
	payload: number | null;
}

export interface IOnSetTargetReferenceObjectPosition {
	(payload: number | null): IOnSetTargetReferenceObjectPosition_Action;
}

export interface IOnSetPopOutIdealTopPosition {
	(payload: number | null): IOnSetPopOutIdealTopPosition_Action;	
}

export interface IOnSetShowFaceLandmarks_Action {
	type: IUserActionTypes.SET_SHOW_FACE_LANDMARKS;
	payload: boolean;
}

export interface IOnSetShowFaceLandmarks {
	(payload: boolean): IOnSetShowFaceLandmarks_Action;
}

export interface IOnSetDropdownHoveredFacelandmark_Action {
	type: IUserActionTypes.SET_HOVERED_FACE_LANDMARK_DROPDOWN;
	payload: IFaceLandmark | null;
}

export interface IOnSetDropdownHoveredFacelandmark {
	(payload: IFaceLandmark | null): IOnSetDropdownHoveredFacelandmark_Action;
}

export interface IOnSetEmitterAreaScales_Action {
	type: IUserActionTypes.SET_EMITTER_AREA_SCALES;
	payload: IVector3Dict | null;
}

export interface IOnSetEmitterAreaScales {
	(payload: IVector3Dict | null): IOnSetEmitterAreaScales_Action;
}

export interface IOnSetEmitterIsStatic_Action {
	type: IUserActionTypes.SET_EMITTER_IS_STATIC;
	payload: {[id: string]: boolean}
}

export interface IOnSetEmitterIsStatic {
	(payload: {[id: string]: boolean}): IOnSetEmitterIsStatic_Action;
}

export interface IOnSetPositions_Action {
	type: IUserActionTypes.SET_POSITIONS;
	payload: IVector3Dict | null;
}

export interface IOnSetPositions {
	(payload: IVector3Dict | null): IOnSetPositions_Action;
}

export interface IOnSetCurvatures_Action {
	type: IUserActionTypes.SET_CURVATURES;
	payload: INumberDict | null;
}

export interface IOnSetCurvatures {
	(payload: INumberDict | null): IOnSetCurvatures_Action;
}

export interface IOnSetScales_Action {
	type: IUserActionTypes.SET_SCALES;
	payload: IVector3Dict | null;
}

export interface IOnSetScales {
	(payload: IVector3Dict | null): IOnSetScales_Action;
}

export interface IOnSetRotations_Action {
	type: IUserActionTypes.SET_ROTATIONS;
	payload: IVector3Dict | null;
}

export interface IOnSetRotations {
	(payload: IVector3Dict | null): IOnSetRotations_Action;
}

export interface IOnSetFillRgba_Action {
	type: IUserActionTypes.SET_FILL_RGBA;
	payload: {[key: string]: IVector4[]} | null;
}

export interface IOnSetFillRgba {
	(payload: {[key: string]: IVector4[]} | null): IOnSetFillRgba_Action;
}

export interface IOnSetFontRgba_Action {
	type: IUserActionTypes.SET_FONT_RGBA;
	payload: IVector4Dict | null;
}

export interface IOnSetFontRgba {
	(payload: IVector4Dict | null): IOnSetFontRgba_Action;
}

export interface IOnSetBorderRgba_Action {
	type: IUserActionTypes.SET_BORDER_RGBA;
	payload: IVector4Dict | null;
}

export interface IOnSetBorderRgba {
	(payload: IVector4Dict | null): IOnSetBorderRgba_Action;
}

export interface IOnSetActiveFaceLandmark_Action {
	type: IUserActionTypes.SET_ACTIVE_FACE_LANDMARK;
	payload: IFaceLandmark | null;
}

export interface IOnSetActiveFaceLandmark {
	(payload: IFaceLandmark | null): IOnSetActiveFaceLandmark_Action;
}

export interface IOnSetMultipleEntitySelectBoundary {
	(payload: IBounds | null): IOnSetMultipleEntitySelectBoundary_Action;
}

export interface IOnSetMultipleEntitySelectBoundary_Action {
	type: IUserActionTypes.SET_MULTIPLE_ENTITY_SELECT_BOUNDARY;
	payload: IBounds | null
}

// Sets user id on page load
export interface ISetUserIdAction {
	type: IUserActionTypes.SET_USER_ID;
	payload: { userId: string };
}

export interface IOnSetUserId {
	(userId: string): ISetUserIdAction;
}

export interface IOnSetReplaceEntityMode {
	(replaceEntityMode: boolean): IOnSetReplaceEntityModeAction;
}

export interface IOnSetVisibleCanvasAction {
	type: IUserActionTypes.SET_VISIBLE_CANVAS;
	payload: { canvasName: ICanvasName; visible: boolean };
}
export interface IOnSetVisibleCanvas {
	(canvasName: ICanvasName, visible: boolean): IOnSetVisibleCanvasAction;
}

export interface IOnSetTempAnimateModelActionAction {
	type: IUserActionTypes.SET_TEMP_ANIMATE_MODEL_ACTION;
	payload: {
		action: ({ type: IActionCategory.animateModel } & IModel3dAnimationOptions) | null;
	};
}

export interface IOnSetTempAnimateModelAction {
	(action: ({ type: IActionCategory.animateModel } & IModel3dAnimationOptions) | null): IOnSetTempAnimateModelActionAction;
}

export interface IOnSetFileVerifying {
	(isVerifying: boolean): IOnSetFileVerifyingAction;
}

// Entity menu drawer action related
export interface IOnSetOpenEntityMenuDrawerAction {
	type: IUserActionTypes.SET_OPEN_ENTITY_MENU_DRAWER;
	payload: {
		openEntityMenuDrawer: IEntityDrawerTypes;
	};
}
export interface IOnSetOpenEntityMenuDrawer {
	(payload: IEntityDrawerTypes): IOnSetOpenEntityMenuDrawerAction;
}

// Entity menu sub-drawer action related
export interface IOnSetOpenEntityMenuDrawerCategoryAction {
	type: IUserActionTypes.SET_OPEN_ENTITY_MENU_CATEGORY_DRAWER;
	payload: {
		openEntityCategoryDrawerDict: IOpenEntityCategoryDrawerDict;
	};
}
export interface IOnSetOpenEntityMenuCategoryDrawer {
	(payload: IOpenEntityCategoryDrawerDict): IOnSetOpenEntityMenuDrawerCategoryAction;
}

// Inspector menu entity drawer related
export interface IOnSetOpenInspectorMenuEntityShelfAction {
	type: IUserActionTypes.SET_OPEN_INSPECTOR_MENU_ENTITY_SHELF;
	payload: IEntityShelves | null;
}
export interface IOnSetOpenInspectorMenuEntityShelf {
	(payload: IEntityShelves | null): IOnSetOpenInspectorMenuEntityShelfAction;
}


// Inspector menu project drawer related
export interface IOnSetOpenProjectMenuDrawerAction {
	type: IUserActionTypes.SET_OPEN_PROJECT_MENU_DRAWER;
	payload: IProjectShelves[] | null;
}

export interface IOnSetOpenProjectMenuDrawer {
	(payload: IProjectShelves[] | null): IOnSetOpenProjectMenuDrawerAction;
}

// Inspector menu scene drawer related
export interface IOnSetOpenSceneMenuDrawerAction {
	type: IUserActionTypes.SET_OPEN_SCENE_MENU_DRAWER;
	payload: ISceneShelves[] | null;
}

export interface IOnRemoveLocalUserSelectionAction {
	type: IUserActionTypes.REMOVE_LOCAL_USER_SELECTION;
}

///

export interface ISetPRojectInfoAction {
	type: IUserActionTypes.SET_PROJECT_INFO;
	payload: {
		project: IProjectData;
	};
}

export interface ISetProjectInfo {
	(payload: { project: IProjectData }): ISetPRojectInfoAction;
}

export interface ISetHotKeyAction {
	type: IUserActionTypes.SET_HOTKEY;
	payload: { hotKey: string };
}

export interface IOnSetCanvasLoadedAction {
	type: IUserActionTypes.CANVAS_IS_LOADED;
	payload: boolean;
}

export interface IOnSetCanvasLoaded {
	(payload: boolean): IOnSetCanvasLoadedAction;
}

export interface ISetTrkImgIsUploadingAction {
	type: IUserActionTypes.SET_TRACKING_IMG_UPLOADING;
	payload: boolean;
}

export interface ISetTrkImgIsUploading {
	(payload: boolean): ISetTrkImgIsUploadingAction;
}

export interface IOnSetLoadingMediaAction {
	type: IUserActionTypes.LOADING_MEDIA;
	payload: boolean;
}

export interface IOnSetLoadingMedia {
	(payload: boolean): IOnSetLoadingMediaAction;
}

export interface ISetLoadedAudioAction {
	type: IUserActionTypes.SET_LOADED_AUDIO;
	payload: IAudio[];
}


export interface ISetLoaded360BackgroundAction {
	type: IUserActionTypes.SET_LOADED_360_BACKGROUND;
	payload: (IBackgroundContent & {statusUrl: string})[];
}


export interface IRemoveUploadAction {
	type: IUserActionTypes.REMOVE_UPLOAD;
	payload: string;
}

export interface IRemoveUpload {
	(id: string): IRemoveUploadAction;
}
interface ISetCurrentUploadsPayload {
	id: string;
	progress: IUploadInfo;
}
export interface ISetCurrentUploadsAction {
	type: IUserActionTypes.SET_CURRENT_UPLOADS;
	payload: ISetCurrentUploadsPayload;
}

export interface IOnSetCurrentUploads {
	(id: string, progress: IUploadInfo): ISetCurrentUploadsAction;
}

export interface IOnSetAudioStatusPayload {
	id: string;
	status: IAudioStatus | null;
}

export interface IOnSetAudioStatusAction {
	type: IUserActionTypes.SET_AUDIO_STATUS;
	payload: IOnSetAudioStatusPayload;
}

export interface IOnSetAudioStatus {
	(payload: IOnSetAudioStatusPayload): IOnSetAudioStatusAction;
}

export interface IOnSetEntityMenuDragActiveAction {
	type: IUserActionTypes.SET_ENTITY_MENU_DRAG_ACTIVE;
	payload: boolean;
}

export interface IOnSetEntityMenuDragActive {
	(payload: boolean): IOnSetEntityMenuDragActiveAction;
}

export interface IOnSetEntityMenuHotspotDragPositionAction {
	type: IUserActionTypes.SET_ENTITY_MENU_DRAG_HOTSPOT_POSITION;
	payload: IVector3 | null;
}

export interface IOnSetScreenRelativeDeviceAction {
	type: IUserActionTypes.SET_SCREEN_RELATIVE_DEVICE;
	payload: IScreenRelativeDevice;
}

export interface IOnSetScreenRelativeDevicePreviousCustomDimensionsAction {
	type: IUserActionTypes.SET_SCREEN_RELATIVE_DEVICE_PREVIOUS_CUSTOM_DIMENSIONS;
	payload: IScreenRelativeDeviceDimensions;
}

export interface IOnSetScreenRelativeDevicePreviousCustomDimensions {
	(payload: IScreenRelativeDeviceDimensions): IOnSetScreenRelativeDevicePreviousCustomDimensionsAction;
}

export interface IOnSetScreenRelativeDevice {
	(payload: IScreenRelativeDevice): IOnSetScreenRelativeDeviceAction;
}

export interface IOnSetEntityMenuHotspotDragPosition {
	(payload: IVector3 | null): IOnSetEntityMenuHotspotDragPositionAction;
}
export interface IOnSetSocketConnectedAction {
	type: IUserActionTypes.SET_SOCKET_CONNECTED;
	payload: boolean;
}

export interface IOnSetProjectLoadingProgressAction {
	type: IUserActionTypes.SET_PROJECT_LOADING_PROGRESS;
	payload: number;
}

export interface IOnSetProjectLoadingFailureAction {
	type: IUserActionTypes.SET_PROJECT_LOADING_FAILURE;
	payload: boolean;
}

export interface IOnSetDraggedEntityInfoAction {
	type: IUserActionTypes.SET_ENTITY_MENU_DRAG_INFO;
	payload: (ISpatialComponentUnion & IDragState) | null;
}

export interface IOnSetDraggedEntityInfo {
	(payload: (ISpatialComponentUnion & IDragState) | null): IOnSetDraggedEntityInfoAction;
}

export interface IOnSetHotKey {
	(hotKey: string): ISetHotKeyAction;
}

export interface IRemoveHotKeyAction {
	type: IUserActionTypes.REMOVE_HOTKEY;
	payload: { hotKey: string };
}

export interface IOnRemoveHotKey {
	(hotKey: string): IRemoveHotKeyAction;
}

export interface IOnAddToastAction {
	type: IUserActionTypes.ADD_TOAST;
	payload: IBaseToast | null;
}

export interface IOnAddToast {
	(payload: IBaseToast | null): IOnAddToastAction;
}

export interface IOnRemoveToastAction {
	type: IUserActionTypes.REMOVE_TOAST;
	payload: string;
}

export interface IOnRemoveToast {
	(payload: string): IOnRemoveToastAction;
}

export interface ISetActiveSceneAction {
	type: IUserActionTypes.SET_ACTIVE_SCENE;
	payload: { activeSceneId: string };
}

export interface IOnSetActiveScene {
	(activeSceneProps: { activeSceneId: string }): ISetActiveSceneAction;
}

export interface IOnSetEnlargeSceneMenuAction {
	type: IUserActionTypes.SET_SCENE_MENU_LARGE;
	payload: boolean;
}

export interface IOnFocusSceneNameInputAction {
	type: IUserActionTypes.FOCUS_SCENE_NAME_INPUT;
	payload: boolean;
}

export interface IOnFocusSceneNameInput {
	(isFocus: boolean): IOnFocusSceneNameInputAction;
}

export interface IOnSetEnlargeSceneMenu {
	(isLarge: boolean): IOnSetEnlargeSceneMenuAction;
}

export interface ISetSceneEntityListVisibleAction {
	type: IUserActionTypes.SET_SCENE_ENTITY_LIST_VISIBLE;
	payload: {
		isVisible: boolean;
		position?: [number, number];
	};
}

export interface IOnSetSceneEntityListVisible {
	(isVisibleProps: { isVisible: boolean; position?: [number, number] }): ISetSceneEntityListVisibleAction;
}

interface GroupInversionPayload {
	xInverted?: boolean;
	yInverted?: boolean;
}
export interface ISetGroupInversionAction {
	type: IUserActionTypes.SET_GROUP_INVERSION;
	payload: GroupInversionPayload;
}

export interface IOnSetGroupInversion {
	(axisInversions: GroupInversionPayload): ISetGroupInversionAction;
}

export interface IOnEditSceneTitleAction {
	type: IUserActionTypes.EDIT_SCENE_TITLE;
	payload: boolean;
}

export interface IOnEditSceneTitle {
	(editScene: boolean): IOnEditSceneTitleAction;
}

export interface IOnChangeZoomLevelAction {
	type: IUserActionTypes.CHANGE_ZOOM_LEVEL;
	payload: number;
}

export interface IOnChangeZoomLevel {
	(zoomLevel: number): IOnChangeZoomLevelAction;
}
export interface IOnSetPositioningIsActiveAction {
	type: IUserActionTypes.SET_POSITIONING_ACTIVE;
	payload: boolean;
}

export interface IOnSetPositioningIsActive {
	(positioningIsActive: boolean): IOnSetPositioningIsActiveAction;
}

interface IOnSetTrackingImageDataPayload {
	aspectRatio?: number;
	codes?: string[];
	compositeUrl?: string;
	quality?: number;
}

export interface IOnSetTrackingImageDataAction {
	type: IUserActionTypes.SET_TRACKING_IMG_DATA;
	payload: IOnSetTrackingImageDataPayload;
}

export interface IOnSetTrackingImageData {
	(payload: IOnSetTrackingImageDataPayload): IOnSetTrackingImageDataAction;
}

export interface IOnRemoveTrackingImageData {
	(): { type: IUserActionTypes.REMOVE_TRACKING_IMG_DATA };
}

export interface IOnSetSelection_Action {
	type: IUserActionTypes.SET_SELECTED_ENTIY_IDS;
	payload: string[];
}

// Enable/disable scale hotspot
export interface ISetScaleHotSpotEnabledAction {
	type: string;
	payload: boolean;
}

export interface IOnSetScaleHotSpotEnabled {
	(scaleHotspotIsEnabled: boolean): ISetScaleHotSpotEnabledAction;
}

// Enable/disable background hotspot (drag etc.)
export interface ISetBackgroundHotSpotEnabledAction {
	type: string;
	payload: boolean;
}

export interface IOnSetBackgroundHotSpotEnabled {
	(backgroundHotspotIsEnabled: boolean): ISetBackgroundHotSpotEnabledAction;
}

export interface IOnSetPureReactInCanvasHoveredAction {
	type: IUserActionTypes.SET_PURE_REACT_IN_CANVAS_HOVERED;
	payload: boolean;
}
export interface IOnSetIs3dModeAction {
	type: IUserActionTypes.SET_3D_MODE;
	payload: boolean;
}

export interface IOnOpenModalAction {
	type: IUserActionTypes.OPEN_MODAL;
	payload: IModal;
}

export interface IOnOpenModal {
	(modalId: IModal) : IOnOpenModalAction
}

export interface IOnCloseModalAction {
	type: IUserActionTypes.CLOSE_MODAL;
}

export interface IOnCloseModal {
	(): IOnCloseModalAction;
}

export interface IOnSetIsScreenRelativeModeAction {
	type: IUserActionTypes.SET_SCREEN_RELATIVE_MODE;
	payload: boolean;
}

export interface IOnSetReplaceEntityModeAction {
	type: IUserActionTypes.SET_REPLACE_ENTITY_MODE;
	payload: {
		replaceEntityMode: boolean;
	};
}
export interface IOnSetIsWebEmbedEditingModeAction {
	type: IUserActionTypes.SET_WEB_EMBED_EDITING_MODE;
	payload: boolean;
}
export interface IOnSetFileVerifyingAction {
	type: IUserActionTypes.SET_FILE_VERIFYING;
	payload: {
		isVerifying: boolean;
	};
}
export interface IOnSetIs3dMode {
	(payload: boolean): IOnSetIs3dModeAction;
}

export interface IOnSetIsScreenRelativeMode {
	(payload: boolean): IOnSetIsScreenRelativeModeAction;
}

export interface IOnSetMediaLibraryDisplayAction {
	type: IUserActionTypes.SET_MEDIA_LIBRARY_DISPLAY;
	payload: Partial<IMediaLibraryDisplay>;
}

export interface IOnSetMediaLibraryDisplay {
	(payload: Partial<IMediaLibraryDisplay>): IOnSetMediaLibraryDisplayAction;
}

export interface IOnSetMediaLibraryAudioAction {
	type: IUserActionTypes.SET_MEDIA_LIBRARY_AUDIO;
	payload: IAudio | null;
}

export interface IOnSetMediaLibraryAudio {
	(payload: IAudio | null): IOnSetMediaLibraryAudioAction;
}

export interface IOnSetTransformControlsActiveAction {
	type: IUserActionTypes.SET_TRANSFORM_CONTROLS_ACTIVE;
	payload: boolean;
}

export interface IOnSetTransformControlsActive {
	(payload: boolean): IOnSetTransformControlsActiveAction;
}

export interface IOnSetTransformControlsModeAction {
	type: IUserActionTypes.SET_TRANSFORM_CONTROLS_MODE;
	payload: ITransformControlsMode;
}

export interface IOnSetTransformControlsMode {
	(payload: ITransformControlsMode): IOnSetTransformControlsModeAction;
}

export interface IOnSetPureReactInCanvasHovered {
	(pureReactIsHovered: boolean): ISetBackgroundHotSpotEnabledAction;
}

export interface IOnSetActiveEditingActionData {
	(payload: IActiveEditingActionData): IOnSetActiveEditingActionData_Action;
}

export interface IOnSetChromaKeyModalData {
	(chromaKeyModalData: Partial<IChromaKeyEditModalData>): IOnSetChromaKeyEditModalDataAction;
}

export interface IOnSetChromaKeyEditModalDataAction {
	type: IUserActionTypes.SET_CHROMA_KEY_EDIT_MODAL_DATA;
	payload: Partial<IChromaKeyEditModalData>;
}

export interface IOnSetWebEmbedPreviewSettingsAction {
	type: IUserActionTypes.SET_WEB_EMBED_PREVIEW_SETTINGS;
	payload: Partial<IWebEmbedPreviewSettings>;
}

// Enable/disable rotation hotspot
export interface ISetRotationHotSpotEnabledAction {
	type: string;
	payload: boolean;
}

export interface ISetSelection2DPositionAction {
	type: IUserActionTypes.SET_SELECTION_2D_POSITION;
	payload: IVector2 | null;
}

export interface ISetSelection2DTopLeftPositionAction {
	type: IUserActionTypes.SET_SELECTION_2D_TOP_LEFT_POSITION;
	payload: IVector2 | null;
}

export interface ISetSelection2DTopRightPositionAction {
	type: IUserActionTypes.SET_SELECTION_2D_TOP_RIGHT_POSITION;
	payload: IVector2 | null;
}

export interface ISetSelection2DBottomLeftPositionAction {
	type: IUserActionTypes.SET_SELECTION_2D_BOTTOM_LEFT_POSITION;
	payload: IVector2 | null;
}

export interface ISetSelection2DBottomRightPositionAction {
	type: IUserActionTypes.SET_SELECTION_2D_BOTTOM_RIGHT_POSITION;
	payload: IVector2 | null;
}

export interface ISetRotationMarker2DPositionAction {
	type: IUserActionTypes.SET_ROTATION_MARKER_2D_POSITION;
	payload: IVector2 | null;
}

export interface IOnSetRotationIsActive {
	(rotationIsActive: boolean): ISetRotationHotSpotEnabledAction;
}

export interface ISetMarkerIndexPressedAction {
	type: string;
	payload: number | null;
}

export interface IOnSetMarkerIndexPressed {
	(markerIndexPressed: number | null): ISetMarkerIndexPressedAction;
}

// Set click offsets for entities and group selections
export interface ISetPointerOffsetAction {
	type: string;
	payload: IVector3;
}

export interface IOnSetPointerOffset {
	(pointerOffset: IVector3): ISetPointerOffsetAction;
}

export interface ISetCurrentSceneIndexAction {
	type: IUserActionTypes.SET_CURRENT_SCENE_INDEX;
	payload: number;
}

export interface IOnSetCurrentSceneIndex {
	(currentIndex: number): ISetCurrentSceneIndexAction;
}

export interface IOnScrollSceneMenuAction {
	type: IUserActionTypes.SCROLL_SCENE_MENU;
	payload: boolean;
}

export interface IOnScrollSceneMenu {
	(scroll: boolean): IOnScrollSceneMenuAction;
}

export interface ISceneSnapshotDict {
	[id: string]: string;
}

export interface ISetSceneSnapshotsAction {
	type: IUserActionTypes.SET_SCENE_SNAPSHOTS;
	payload: ISceneSnapshotDict;
}

export interface IOnSetSceneSnapshots {
	(sceneShots: ISceneSnapshotDict): ISetSceneSnapshotsAction;
}

export interface IOnSetSceneCarouselScrollLeftAction {
	type: IUserActionTypes.SET_SCENE_CAROUSEL_SCROLL_LEFT;
	payload: number;
}

export interface IOnSetSceneCarouselScrollLeft {
	(scrollLeft: number): IOnSetSceneCarouselScrollLeftAction;
}

// Enable/disable undo
export interface IDisableUndoAction {
	type: string;
	payload: boolean;
}

export interface IDisableRedoAction {
	type: string;
	payload: boolean;
}

// Add entity ids to selection
export interface IAddEntityIdsToSelectionAction {
	type: string;
	payload: string[];
}

export interface IOnAddEntityIdsToSelection {
	(ids: string[]): IAddEntityIdsToSelectionAction;
}

// Remove entity ids to selection
export interface IOnRemoveSelection {
	(ids?: string[]): { type: string };
}

// Add entity ids to copy selection
export interface ICopySelectedEntitiesAction {
	type: IUserActionTypes.COPY_SELECTED_ENTITIES;
	payload: string[];
}

export interface IOnCopySelectedEntities {
	(ids: string[]): ICopySelectedEntitiesAction;
}

export interface IOnUpdateUsers_Payload {
	users: ISyncDocUser[];
}

export interface IOnUpdateUsers_Action {
	type: IUserActionTypes.UPDATE_USERS;
	payload: IOnUpdateUsers_Payload;
}

export interface IOnUpdateUsers {
	(payload: IOnUpdateUsers_Payload): IOnUpdateUsers_Action;
}

interface IZmlSearchParams {
	limit?: number; 
	page?: number; 
	q?: string
}

export interface IOnGetZmlFileSearchInput extends IZmlSearchParams {
	type: IComponentType.Video | IComponentType.Image | IComponentType.Model3d; 
	sort?: string, 
	status?: IFileStatus, 
	isPublic?: boolean
}

export interface IZmlSearchResponseData {
	searchResponse: ISearchResponse<(IFileImage | IFileStreamingVideo | IFileModel3D)[]>
}

export interface IOnGetZmlFileInfo {
	(args: IOnGetZmlFileSearchInput): (dispatch: Dispatch) => void;
	(args: IZmlSearchResponseData): (dispatch: Dispatch) => void;
}

export interface IOnGetAudioFileInfo {
	(args: IZmlSearchParams): (dispatch: Dispatch) => void;
}

export interface IOnGet360BackgroundFileInfo {
	(args: IZmlSearchParams): (dispatch: Dispatch) => void;
}

export enum IAddMediaPositionTypes {
	start = 'start',
	end = 'end',
	date = 'date',
}

export enum ILoadedMediaTypes {
	image = 'image',
	background360 = 'background360',
	model3d = 'model3d',
	video = 'video',
	audio = 'audio',
	zpp = 'zpp',
	trackingImage = 'trackingImage',
}

export interface IOnAddToLoadedMediaPayload {
	position?: IAddMediaPositionTypes;
	entity: ILoadedComponentUnion;
}

export interface IOnAddToLoadedMediaAction {
	type: IUserActionTypes.ADD_TO_LOADED_MEDIA;
	payload: IOnAddToLoadedMediaPayload;
}

export interface IOnAddToLoadedAudioPayload {
	position?: IAddMediaPositionTypes;
	entity: IAudio;
}

export interface IOnAddToLoadedAudioAction {
	type: IUserActionTypes.ADD_TO_LOADED_AUDIO;
	payload: IOnAddToLoadedAudioPayload;
}

export interface IOnAddToLoadedMedia {
	(payload: IOnAddToLoadedMediaPayload): IOnAddToLoadedMediaAction;
}
export interface IOnAddToLoadedAudio {
	(payload: IOnAddToLoadedAudioPayload): IOnAddToLoadedAudioAction;
}

export interface IOnRemoveLoadedMediaPayload {
	type: IZmlComponentUnion['type'];
}

export interface IOnRemoveLoadedMediaAction {
	type: IUserActionTypes.REMOVE_LOADED_MEDIA;
	payload: IOnRemoveLoadedMediaPayload;
}

export interface IOnRemoveLoadedMedia {
	(payload: IOnRemoveLoadedMediaPayload): IOnRemoveLoadedMediaAction;
}

export interface InSetDragControlsUpdateTriggerAction {
	type: IUserActionTypes.SET_DRAG_CONTROLS_UPDATE_TRIGGER;
	payload: number;
}

export interface InSetDragControlsUpdateTrigger {
	(payload: number): InSetDragControlsUpdateTriggerAction;
}

export interface IOnSetTemporaryInitialDragRotationAction {
	type: IUserActionTypes.SET_TEMP_INITIAL_ROTATION;
	payload: ITuple3 | null;
}

export interface IOnSetTemporaryInitialDragRotation {
	(payload: ITuple3 | null): IOnSetTemporaryInitialDragRotationAction
}


/////////////////
// Content Doc
////////////////
export interface IMultipleEntityProps_Cn_Doc {
	id: string;
	opacity?: number;
	rotation?: IVector3 | null;
	position?: IVector3 | null;
	scale?: IVector3 | null;
	origScale?: IVector3;
	color?: IVector4[] | null;
	fontRgba?: IVector4 | null;
	text?: string;
	fontFamily?: IFontTypes;
	fontSize?: number;
	borderRadius?: number;
	borderWidth?: number;
	borderRgba?: IVector4 | null;
	bkgrSound?: IBkgrSound | null;
	children?: string[];
	type?: IComponentType;
	textAlignment?: ITextAlignment;
	loop?: boolean;
	autoplay?: boolean;
	hideControls?: boolean;
	stopMedia?: boolean;
	trackingType?: ITrackingTypes;
	idleAnimation?: string;
	aspectRatioLocked?: boolean;
	isLocked?: boolean;
	isHidden?: boolean;
	title?: string;
	scalesInverted?: [boolean, boolean];
	renderOrder?: number;
	idlePoseTime?: number;
	animations?: IModel3dAnimation[];
	curvature?: number;
	isSnappedToTarget?: boolean;
	hasAlpha?: boolean;
	chromaKey?: IChromaKey;
	category?: IButtonCategory;
	subCategory?: IButtonSubCategory;
	svgUrl?: string;
	castShadow?: boolean;
	receiveShadow?: boolean;
	muted?: boolean;
	is3dCompatible?: boolean;
	depth?: number;
	curveSegments?: number;
	bevelEnabled?: boolean;
	normalMaterial?: boolean;
	bevelThickness?: number;
	bevelSize?: number;
	bevelOffset?: number;
	bevelSegments?: number;
	isTextScaleLocked?: boolean;
}

export interface IOnSetProjectShadowsEnabled_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_PROJECT_SHADOWS_ENABLED_CN_DOC;
	payload: boolean;
}

export interface IOnSetProjectShadowsEnabled_Cn_Doc {
	(payload: boolean): IOnSetProjectShadowsEnabled_Cn_Doc_Action;
}

export interface IOnSetDevice_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_DEVICE_CN_DOC,
	payload: IDeviceType | undefined
}

export interface IOnSetDeviceType_Cn_Doc {
	(payload: IDeviceType | undefined): IOnSetDevice_Cn_Doc_Action;
}

export interface IOnSetDisableHLSvideoStreaming_Cn_Doc_Payload {
	sceneId: string;
	hlsDisabled: boolean;
}

export interface IOnSetDisableHLSvideoStreaming_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_DISABLE_HLS_VIDEO_STREAMING_CN_DOC;
	payload: IOnSetDisableHLSvideoStreaming_Cn_Doc_Payload;
}

export interface IOnSetDisableHLSvideoStreaming_Cn_Doc {
	(payload: IOnSetDisableHLSvideoStreaming_Cn_Doc_Payload): IOnSetDisableHLSvideoStreaming_Cn_Doc_Action;
}

export interface IOnSetChromaKeyProperties_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_CHROMA_KEY_PROPERTIES_CN_DOC;
	payload: Partial<IChromaKey> & { entityId: string};
}

export interface IOnSetChromaKeyProperties_Cn_Doc {
	(payload: Partial<IChromaKey> & { entityId: string}): IOnSetChromaKeyProperties_Cn_Doc_Action;
}

export interface IOnSetInitialTemplateSlug_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_INITIAL_TEMPLATE_SLUG;
	payload: string;
}

export interface IOnSetInitialTemplateSlug_Cn_Doc {
	(payload: string): IOnSetInitialTemplateSlug_Cn_Doc_Action;
}

export interface IOnSetTargetImageReferenceObject_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_TARGET_IMAGE_REFERENCE_OBJECT_CN_DOC;
	payload: Partial<ITargetImageReferenceObjectData>;
}

export interface IOnSetTargetImageReferenceObject_Cn_Doc {
	(payload: Partial<ITargetImageReferenceObjectData>): IOnSetTargetImageReferenceObject_Cn_Doc_Action;
}

export interface IOnSetAnalyticsTracking_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_ANALYTICS_TRACKING;
	payload: IAnalyticsTrackingDict;
}

export interface IOnSetAnalyticsTracking_Cn_Doc {
	(analyticsTrackingDict: IAnalyticsTrackingDict): IOnSetAnalyticsTracking_Cn_Doc_Action;
}

export interface IReplaceEntity_Cn_Doc_Action {
	type: IContentDocActionTypes.REPLACE_ENTITY_CN_DOC;
	payload: {
		entity: ISpatialComponentUnion;
		replacementEntity: ISpatialComponentUnion;
	};
}

export interface IOnRemoveImageTracking_Cn_Doc_Action {
	type: IContentDocActionTypes.REMOVE_IMAGE_TRACKING_CN_DOC;
}
export interface IOnSetMultipleComponentProps_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_MULTIPLE_COMPONENT_PROPS_CN_DOC;
	payload: IMultipleEntityProps_Cn_Doc[];
}

export interface IOnSetMultipleComponentProps_Cn_Doc {
	(entityProps: IMultipleEntityProps_Cn_Doc[]): IOnSetMultipleComponentProps_Cn_Doc_Action;
}

export interface IOnSetWebEmbed_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_WEB_EMBED_CN_DOC;
	payload: Partial<IWebEmbedSettings>;
}

export interface IOnSetWebEmbed_Cn_Doc {
	(webEmbed: Partial<IWebEmbedSettings>): IOnSetWebEmbed_Cn_Doc_Action;
}

// Copy screen contents

export interface IOnCopyScreenSceneContent_Cn_Doc_Action {
	type: IContentDocActionTypes.COPY_SCREEN_SCENE_CONTENT;
	payload: {
		activeSceneId: string;
		targetSceneId: string;
	};
}

export interface IOnCopyScreenSceneContent_Cn_Doc {
	(activeSceneId: string, targetSceneId: string): IOnCopyScreenSceneContent_Cn_Doc_Action;
}

// Remove idle pose time
export interface IOnRemoveIdlePoseTime_Cn_Doc_Action {
	type: IContentDocActionTypes.REMOVE_IDLE_POSE_TIME;
	payload: {
		entityId: string;
	}
}
export interface IOnRemoveIdlePoseTime_Cn_Doc {
	(entityId: string): IOnRemoveIdlePoseTime_Cn_Doc_Action;
}

// Remove idle animation
export interface IOnRemoveIdleAnimation_Cn_Doc_Action {
	type: IContentDocActionTypes.REMOVE_IDLE_ANIMATION;
	payload: {
		entityId: string;
	}
}
export interface IOnRemoveIdleAnimation_Cn_Doc {
	(entityId: string): IOnRemoveIdleAnimation_Cn_Doc_Action;
}

// Clear screen contents

export interface IOnClearScreenSceneContent_Cn_Doc_Action {
	type: IContentDocActionTypes.CLEAR_SCREEN_SCENE_CONTENT;
	payload: string;
}

export interface IOnClearScreenSceneContent_Cn_Doc {
	(sceneId: string): IOnClearScreenSceneContent_Cn_Doc_Action;
}

////

// Undo & Redo
export interface ISetUndoAction {
	type: IContentDocActionTypes.UNDO_CN_DOC;
}
export type IOnUndoCnDoc = () => ISetUndoAction;

export interface ISetRedoAction {
	type: IContentDocActionTypes.REDO_CN_DOC;
}
export type IOnRedoCnDoc = () => ISetRedoAction;

// Reparenting components
export interface IOnReparentComponent_Cn_Doc_Payload {
	id: string;
	oldParentId: string;
	newParentId: string;
}
export interface IOnReparentComponent_Cn_Doc_Action {
	type: IContentDocActionTypes.REPARENT_COMPONENT_CN_DOC;
	payload: IOnReparentComponent_Cn_Doc_Payload;
}
export interface IOnReparentComponent_Cn_Doc {
	(payload: IOnReparentComponent_Cn_Doc_Payload): IOnReparentComponent_Cn_Doc_Action;
}

// Set Emitter Properties

export interface IOnSetEmitterProperties_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_EMITTER_PROPERTIES_CN_DOC;
	payload: {settings: Partial<IEmitterSettings>, entityId: string}
}

export interface IOnSetEmitterProperties_Cn_Doc {
	(args: {settings: Partial<IEmitterSettings>, entityId: string}): IOnSetEmitterProperties_Cn_Doc_Action;
}

export interface IOnSetEmitterGroupProperties_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_EMITTER_GROUP_PROPERTIES_CN_DOC;
	payload: {settings: Partial<IEmitterGroupSettings>, entityId: string}
}

export interface IOnSetEmitterGroupProperties_Cn_Doc {
	(args: {settings: Partial<IEmitterGroupSettings>, entityId: string}): IOnSetEmitterGroupProperties_Cn_Doc_Action;
}

export type IOnSetEmitterBaseProperties_Cn_Doc_Payload = {entityId: string, properties: Partial<Omit<IEmitter, 'emitter' | 'emitterGroup'>>}

export interface IOnSetEmitterBaseProperties_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_EMITTER_BASE_PROPERTIES_CN_DOC;
	payload: IOnSetEmitterBaseProperties_Cn_Doc_Payload
}

export interface IOnSetEmitterBaseProperties_Cn_Doc {
	(args: IOnSetEmitterBaseProperties_Cn_Doc_Payload): IOnSetEmitterBaseProperties_Cn_Doc_Action;
}

// Set Position

export interface IOnSetPositions_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_ENTITY_POSITIONS_CN_DOC;
	payload: { [id: string]: IVector3 };
}

export interface IOnSetPositions_Cn_Doc {
	(positionDict: { [id: string]: IVector3 }): IOnSetPositions_Cn_Doc_Action;
}

export interface IOnSetRgbaColors_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_ENTITY_COLORS_CN_DOC;
	payload: { [id: string]: IVector4 };
}

export interface IOnSetRgbaColors_Cn_Doc {
	(colorDict: { [id: string]: IVector4 }): IOnSetRgbaColors_Cn_Doc_Action;
}

// Set Scale Factor
export interface IOnSetScales_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_SCALE_FACTORS_CN_DOC;
	payload: { [id: string]: IVector3 };
}

export interface IOnSetScales_Cn_Doc {
	(scaleDict: { [id: string]: IVector3 }): IOnSetScales_Cn_Doc_Action;
}

// Set Rotation

export interface IOnSetRotations_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_ENTITY_ROTATIONS_CN_DOC;
	payload: { [id: string]: number[] };
}

export interface IOnSetRotations_Cn_Doc {
	(rotationDict: { [id: string]: number[] }): IOnSetRotations_Cn_Doc_Action;
}

// Update text text
export interface IOnUpdateText_Global_Payload {
	id: string;
	text: string;
}

export interface IOnUpdateText_Global {
	(payload: IOnUpdateText_Global_Payload): (Dispatch: Dispatch) => void;
}

/* Add entity to content doc */
export interface IOnAddEntity_Global {
	(payload: { parentId: string; entity: ISpatialComponentUnion; disableAutoSelection?: boolean }): (dispatch: Dispatch) => void;
}

export interface IOnAddEntity_Cn_Doc_Action {
	type: IContentDocActionTypes.ADD_ENTITY_CN_DOC;
	payload: {
		parentId: string;
		entity: ISpatialComponentUnion;
	};
}

export interface IOnRemoveEntities_Global {
	(ids: string[], onlyAbstract?: boolean): (dispatch: Dispatch) => void;
}

export interface IOnRemoveScenes_Global {
	(sceneIds: string[]): undefined | ((dispatch: Dispatch, getState: () => IDesignerState) => void);
}

export interface IOnRemoveEntities_Cn_Doc_Action {
	type: IContentDocActionTypes.REMOVE_ENTITIES_CN_DOC;
	payload: { ids: string[] };
}

export interface IOnRemoveSceneReferences_Cn_Doc_Action {
	type: IContentDocActionTypes.REMOVE_SCENE_REFERENCES_CN_DOC;
	payload: { sceneIds: string[] };
}

interface IOnPasteCopiedEntities_Base_Payload {
	activeSceneId: string;
	copiedIds: string[];
}

export interface IOnPasteCopiedEntities_Global_Payload extends IOnPasteCopiedEntities_Base_Payload {
	offset?: ITuple3;
	newTitle?: string;
}
interface IOnPasteCopiedEntities_Cn_Doc_Payload extends IOnPasteCopiedEntities_Global_Payload {
	isScreenRelativeMode: boolean;
}

export interface IOnPasteCopiedEntities_Global {
	(payload: IOnPasteCopiedEntities_Global_Payload): (dispatch: Dispatch) => void;
}

export interface IPasteCopiedEntities_Cn_Doc_Action {
	type: IContentDocActionTypes.PASTE_COPIED_ENTITIES_CN_DOC;
	payload: IOnPasteCopiedEntities_Cn_Doc_Payload;
}

export interface IOnCopyScenes_Global_Payload {
	projectId: string;
	sceneIds: string[];
	index: number;
}

export interface IOnCopyScenesAtIndex_Global {
	(payload: IOnCopyScenes_Global_Payload): (dispatch: Dispatch, getState: () => IDesignerState) => void;
}

export interface IOnAddScenes_Global_Payload {
	sceneTrackingType: ITrackingTypes;
}

export interface IOnAddScenes_Global {
	(payload: IOnAddScenes_Global_Payload): (dispatch: Dispatch, getState: () => IDesignerState) => void;
}

export interface IOnCopyScenesAtIndex_Cn_Doc_Action {
	type: IContentDocActionTypes.COPY_SCENES_AT_INDEX_CN_DOC;
	payload: { sceneIds: string[]; index: number, adjustPositionToCamera?: boolean };
}
export interface IOnCopyScenesAtIndex_Cn_Doc {
	(sceneProps: { newSceneIds: string[]; sceneIds: string[]; index: number }): IOnCopyScenesAtIndex_Cn_Doc_Action;
}

export interface IOnSetTransition_Cn_Doc_Payload {
	ids: string[];
	category: ITransitionCategoryTypes;
	type: ITransitionEffectTypes;
	duration: number;
	delay: number;
}

export interface IOnSetComponentTransition_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_COMPONENT_TRANSITION_CN_DOC;
	payload: IOnSetTransition_Cn_Doc_Payload;
}

export interface IOnSetComponentTransition_Cn_Doc {
	(payload: IOnSetTransition_Cn_Doc_Payload): IOnSetComponentTransition_Cn_Doc_Action;
}

// Add component action - payload
interface IOnAddComponentAction_Cn_Doc_Payload {
	entityId: string;
	triggerType: ITriggerTypes;
	actionData: ISpatialComponentActionData;
}

// Add component action - action
export interface IOnAddComponentAction_Cn_Doc_Action {
	type: IContentDocActionTypes.ADD_COMPONENT_ACTION_CN_DOC;
	payload: IOnAddComponentAction_Cn_Doc_Payload;
}

// Add component action - action creator function
export interface IOnAddComponentAction_Cn_Doc {
	(payload: IOnAddComponentAction_Cn_Doc_Payload): IOnAddComponentAction_Cn_Doc_Action;
}

// Remove component action - payload
interface IOnRemoveComponentAction_Cn_Doc_Payload {
	entityId: string;
	triggerType: ITriggerTypes;
	index: number;
}

// Remove component action - action
export interface IOnRemoveComponentAction_Cn_Doc_Action {
	type: IContentDocActionTypes.REMOVE_COMPONENT_ACTION_CN_DOC;
	payload: IOnRemoveComponentAction_Cn_Doc_Payload;
}

// Remove component action - action creator function
export interface IOnRemoveComponentAction_Cn_Doc {
	(payload: IOnRemoveComponentAction_Cn_Doc_Payload): IOnRemoveComponentAction_Cn_Doc_Action;
}

// Set component action - payload
interface IOnSetComponentAction_Cn_Doc_Payload {
	entityId: string;
	index: number;
	triggerType: ITriggerTypes;
	actionData: ISpatialComponentActionData;
}

// Set component action - action
export interface IOnSetComponentAction_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_COMPONENT_ACTION_CN_DOC;
	payload: IOnSetComponentAction_Cn_Doc_Payload;
}

// Set component action - action creator function
export interface IOnSetComponentAction_Cn_Doc {
	(payload: IOnSetComponentAction_Cn_Doc_Payload): IOnSetComponentAction_Cn_Doc_Action;
}

type IOnSetProjectColors_Cn_Doc_Payload = IProjectColor[];

export interface IOnSetProjectColors_Cn_Doc_Action {
	type: IContentDocActionTypes.ADD_PROJECT_COLORS_CN_DOC;
	payload: IOnSetProjectColors_Cn_Doc_Payload;
}

export interface IOnSetProjectColors_Cn_Doc {
	(payload: IOnSetProjectColors_Cn_Doc_Payload): IOnSetProjectColors_Cn_Doc_Action;
}

export interface IChange_Cn_Doc_Action {
	type: IContentDocActionTypes.CHANGE_CN_DOC_REDUX;
	contentDoc: IContentDoc;
}

type IOnSetImageTracking_Cn_Doc_Payload = Partial<ITrackingImageData>;

export interface IOnSetImageTracking_Cn_Doc_Action {
	type: IContentDocActionTypes.ADD_IMAGE_TRACKING_CN_DOC;
	payload: IOnSetImageTracking_Cn_Doc_Payload;
}

export interface IonSetImageTracking_Cn_Doc {
	(payload: IOnSetImageTracking_Cn_Doc_Payload): IOnSetImageTracking_Cn_Doc_Action;
}


interface IOnSetActiveSceneChildren_Cn_Doc_Payload {
	sceneChildren: string[];
	activeSceneId: string;
}

export interface IOnSetActiveSceneChildren_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_ACTIVE_SCENE_CHILDREN;
	payload: IOnSetActiveSceneChildren_Cn_Doc_Payload;
}

export interface IOnSetActiveSceneChildren_Cn_Doc {
	(payload: IOnSetActiveSceneChildren_Cn_Doc_Payload): IOnSetActiveSceneChildren_Cn_Doc_Action;
}

export interface IOnSetRootChildren_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_ROOT_CHILDREN;
	payload: string[];
}

export interface IOnSetRootChildren_Cn_Doc {
	(payload: string[]): IOnSetRootChildren_Cn_Doc_Action;
}

export interface IOnAddFaceLandmarkToScene_Cn_Doc_Action {
	type: IContentDocActionTypes.ADD_FACE_LANDMARK_TO_SCENE_CN_DOC;
	payload: { landmark: IFaceLandmark, sceneId: string };
}

export interface IOnAddFaceLandmarkToScene_Cn_Doc {
	(payload: { landmark: IFaceLandmark, sceneId: string }): IOnAddFaceLandmarkToScene_Cn_Doc_Action;
}

export interface IOnAddAnchorGroupToScene_Cn_Doc_Action {
	type: IContentDocActionTypes.ADD_ANCHOR_GROUP_TO_SCENE_CN_DOC;
	payload: { anchorPositionType: IScreenAnchorPositionType, sceneId: string };
}

export interface IOnAddAnchorGroupToScene_Cn_Doc {
	(payload: { anchorPositionType: IScreenAnchorPositionType, sceneId: string }): IOnAddAnchorGroupToScene_Cn_Doc_Action;
}

export interface IOnSetActionTrigger_Cn_Doc_Payload {
	componentId: string;
	actionIndex: number,
	oldTrigger: ITriggerTypes,
	newTrigger: ITriggerTypes,
}
export interface IOnSetActionTrigger_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_ACTION_TRIGGER_CN_DOC;
	payload: IOnSetActionTrigger_Cn_Doc_Payload
}

export interface IOnSetActionTrigger_Cn_Doc {
	(payload: IOnSetActionTrigger_Cn_Doc_Payload): IOnSetActionTrigger_Cn_Doc_Action;
}

export type IOnSetScene360Background_Cn_Doc_Payload = {asset: Omit<IBackgroundContent, 'id'> | null, sceneId: string} | {asset: {type: IFileType.Video360, muted?: boolean, loop?: boolean}, sceneId: string};

export interface IOnSetScene360Background_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_SCENE_360_BACKGROUND,
	payload: IOnSetScene360Background_Cn_Doc_Payload;
}

export interface IOnSetScene360Background_Cn_Doc {
	(payload: IOnSetScene360Background_Cn_Doc_Payload): IOnSetScene360Background_Cn_Doc_Action
}

export interface IOnSetScene360InitialCameraRotation_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_SCENE_360_INITIAL_CAMERA_ROTATION,
	payload: {sceneId: string, rotation: IVector3};
}

export interface IOnSetScene360InitialCameraRotation_Cn_Doc {
	(payload: {sceneId: string, rotation: IVector3}): IOnSetScene360InitialCameraRotation_Cn_Doc_Action
}

interface IOnSetVideoRenditionProgressData_Cn_Doc_Payload extends IVideoProgressData {
	id: string;	
}

export interface IOnSetVideoRenditionProgressData_Cn_Doc_Action {
	type: IContentDocActionTypes.SET_VIDEO_RENDITION_PROGRESS_DATA;
	payload: IOnSetVideoRenditionProgressData_Cn_Doc_Payload | {id: string};
}

export interface IOnSetVideoRenditionProgressData_Cn_Doc {
	(payload: IOnSetVideoRenditionProgressData_Cn_Doc_Payload | {id: string}): IOnSetVideoRenditionProgressData_Cn_Doc_Action
}
