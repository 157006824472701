import { IOnClearScreenSceneContent_Cn_Doc, IOnCopyScreenSceneContent_Cn_Doc, IOnReparentComponent_Cn_Doc, IOnSetRootChildren_Cn_Doc, IOnSetWebEmbed_Cn_Doc } from '..';
import { IAnalyticsTrackingDict } from '../../../components/r3f/r3f-components/component-data-structure/types/analytics';
import {
	IContentDocActionTypes,
	IOnUndoCnDoc,
	IOnRedoCnDoc,
	IOnSetPositions_Cn_Doc,
	IOnSetScales_Cn_Doc,
	IOnSetRotations_Cn_Doc,
	IOnSetRgbaColors_Cn_Doc,
	IOnSetMultipleComponentProps_Cn_Doc,
	IOnSetComponentAction_Cn_Doc,
	IOnSetComponentTransition_Cn_Doc,
	IonSetImageTracking_Cn_Doc,
	IOnSetActiveSceneChildren_Cn_Doc,
	IOnAddComponentAction_Cn_Doc,
	IOnRemoveComponentAction_Cn_Doc,
	IOnAddFaceLandmarkToScene_Cn_Doc,
	IOnAddAnchorGroupToScene_Cn_Doc,
	IOnRemoveIdlePoseTime_Cn_Doc,
	IOnRemoveIdleAnimation_Cn_Doc,
	IOnSetAnalyticsTracking_Cn_Doc,
	IOnSetEmitterProperties_Cn_Doc,
	IOnSetEmitterGroupProperties_Cn_Doc,
	IOnSetEmitterBaseProperties_Cn_Doc,
	IOnSetProjectColors_Cn_Doc,
	IOnSetTargetImageReferenceObject_Cn_Doc,
	IOnSetChromaKeyProperties_Cn_Doc,
	IOnSetProjectShadowsEnabled_Cn_Doc,
	IOnSetInitialTemplateSlug_Cn_Doc,
	IOnSetActionTrigger_Cn_Doc,
	IOnSetDisableHLSvideoStreaming_Cn_Doc,
	IOnSetDeviceType_Cn_Doc,
	IOnSetScene360Background_Cn_Doc,
	IOnSetScene360InitialCameraRotation_Cn_Doc,
	IOnSetVideoRenditionProgressData_Cn_Doc,
} from '../action-types';


export const onSetScene360Background_Cn_Doc: IOnSetScene360Background_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.SET_SCENE_360_BACKGROUND,
	payload
})

export const onSetScene360InitialCameraRotation_Cn_Doc: IOnSetScene360InitialCameraRotation_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.SET_SCENE_360_INITIAL_CAMERA_ROTATION,
	payload
})

export const onSetVideoRenditionProgressData_Cn_Doc: IOnSetVideoRenditionProgressData_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.SET_VIDEO_RENDITION_PROGRESS_DATA,
	payload
})


export const onSetActionTrigger: IOnSetActionTrigger_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.SET_ACTION_TRIGGER_CN_DOC,
	payload
})

export const onSetChromaKeyProperties: IOnSetChromaKeyProperties_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.SET_CHROMA_KEY_PROPERTIES_CN_DOC,
	payload,
});

export const onSetInitialTemplateSlug: IOnSetInitialTemplateSlug_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.SET_INITIAL_TEMPLATE_SLUG,
	payload
});

export const onSetTargetImageReferenceObject: IOnSetTargetImageReferenceObject_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.SET_TARGET_IMAGE_REFERENCE_OBJECT_CN_DOC,
	payload
});

export const onRemoveIdlePoseTime: IOnRemoveIdlePoseTime_Cn_Doc = (entityId) => ({
	type: IContentDocActionTypes.REMOVE_IDLE_POSE_TIME,
	payload: { entityId },
});

export const onRemoveIdleAnimation: IOnRemoveIdleAnimation_Cn_Doc = (entityId) => ({
	type: IContentDocActionTypes.REMOVE_IDLE_ANIMATION,
	payload: { entityId },
});

// Analytics
export const onSetAnalyticsTracking_Cn_Doc: IOnSetAnalyticsTracking_Cn_Doc = (analyticsTrackingDict: IAnalyticsTrackingDict) => ({
	type: IContentDocActionTypes.SET_ANALYTICS_TRACKING,
	payload: analyticsTrackingDict,
});

// Copy screen scene content
export const onCopyScreenSceneContent: IOnCopyScreenSceneContent_Cn_Doc = (activeSceneId, targetSceneId) => ({
	type: IContentDocActionTypes.COPY_SCREEN_SCENE_CONTENT,
	payload: { activeSceneId, targetSceneId },
});

export const onClearScreenSceneContent: IOnClearScreenSceneContent_Cn_Doc = (sceneId: string) => ({
	type: IContentDocActionTypes.CLEAR_SCREEN_SCENE_CONTENT,
	payload: sceneId,
});

// UNDO & REDO
export const onUndo_Cn_Doc: IOnUndoCnDoc = () => ({
	type: IContentDocActionTypes.UNDO_CN_DOC,
});

export const onRedo_Cn_Doc: IOnRedoCnDoc = () => ({
	type: IContentDocActionTypes.REDO_CN_DOC,
});

// Reparenting

export const onReparentComponent_Cn_Doc: IOnReparentComponent_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.REPARENT_COMPONENT_CN_DOC,
	payload,
});

// Set emitter properties 
export const onSetEmitterProperties: IOnSetEmitterProperties_Cn_Doc = payload => ({
	type: IContentDocActionTypes.SET_EMITTER_PROPERTIES_CN_DOC,
	payload
})


export const onSetEmitterGroupProperties: IOnSetEmitterGroupProperties_Cn_Doc = payload => ({
	type: IContentDocActionTypes.SET_EMITTER_GROUP_PROPERTIES_CN_DOC,
	payload
})

export const onSetEmitterBaseProperties: IOnSetEmitterBaseProperties_Cn_Doc = payload => ({
	type: IContentDocActionTypes.SET_EMITTER_BASE_PROPERTIES_CN_DOC,
	payload
})


// Set position, scale & rotation
export const onSetPositions_Cn_Doc: IOnSetPositions_Cn_Doc = (positionDict) => ({
	type: IContentDocActionTypes.SET_ENTITY_POSITIONS_CN_DOC,
	payload: positionDict,
});

export const onSetRotations_Cn_Doc: IOnSetRotations_Cn_Doc = (rotationDict) => ({
	type: IContentDocActionTypes.SET_ENTITY_ROTATIONS_CN_DOC,
	payload: rotationDict,
});

export const onSetScales_Cn_Doc: IOnSetScales_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.SET_SCALE_FACTORS_CN_DOC,
	payload,
});

export const onSetRgbaColors_Cn_Doc: IOnSetRgbaColors_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.SET_ENTITY_COLORS_CN_DOC,
	payload,
});

export const onSetComponentTransition_Cn_Doc: IOnSetComponentTransition_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.SET_COMPONENT_TRANSITION_CN_DOC,
	payload,
});

export const onAddComponentAction_Cn_Doc: IOnAddComponentAction_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.ADD_COMPONENT_ACTION_CN_DOC,
	payload,
});

export const onRemoveComponentAction_Cn_Doc: IOnRemoveComponentAction_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.REMOVE_COMPONENT_ACTION_CN_DOC,
	payload,
});

export const onSetComponentAction_Cn_Doc: IOnSetComponentAction_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.SET_COMPONENT_ACTION_CN_DOC,
	payload,
});

export const onSetMultipleComponentProps_Cn_Doc: IOnSetMultipleComponentProps_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.SET_MULTIPLE_COMPONENT_PROPS_CN_DOC,
	payload,
});

export const onSetWebEmbed_Cn_Doc: IOnSetWebEmbed_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.SET_WEB_EMBED_CN_DOC,
	payload,
});

export const onSetImageTracking_Cn_Doc: IonSetImageTracking_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.ADD_IMAGE_TRACKING_CN_DOC,
	payload,
});

export const onSetActiveSceneChildren_Cn_Doc: IOnSetActiveSceneChildren_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.SET_ACTIVE_SCENE_CHILDREN,
	payload,
});

export const onSetRootChildren_Cn_Doc: IOnSetRootChildren_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.SET_ROOT_CHILDREN,
	payload,
});

export const onAddFaceLandmarkToScene_Cn_Doc: IOnAddFaceLandmarkToScene_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.ADD_FACE_LANDMARK_TO_SCENE_CN_DOC,
	payload,
});

export const onAddAnchorGroupToScene_Cn_Doc: IOnAddAnchorGroupToScene_Cn_Doc = (payload) => ({
	type: IContentDocActionTypes.ADD_ANCHOR_GROUP_TO_SCENE_CN_DOC,
	payload,
});

export const onSetProjectColors_Cn_Doc: IOnSetProjectColors_Cn_Doc = (payload) => {
	return {
		type: IContentDocActionTypes.ADD_PROJECT_COLORS_CN_DOC,
		payload,
	};
};

export const onSetProjectShadowsEnabled_Cn_Doc: IOnSetProjectShadowsEnabled_Cn_Doc = (payload) => {
	return {
		type: IContentDocActionTypes.SET_PROJECT_SHADOWS_ENABLED_CN_DOC,
		payload,
	};
}

export const onSetDeviceType_Cn_Doc: IOnSetDeviceType_Cn_Doc = (payload) => {
	return {
		type: IContentDocActionTypes.SET_DEVICE_CN_DOC,
		payload
	}
}

export const onSetDisableHLSvideoStreaming_Cn_Doc: IOnSetDisableHLSvideoStreaming_Cn_Doc = (payload) => {
	return {
		type: IContentDocActionTypes.SET_DISABLE_HLS_VIDEO_STREAMING_CN_DOC,
		payload,
	}
}
