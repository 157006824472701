import { IFileType } from "./types";
export const getContentTypeFromIFileType = (t) => {
    switch (t) {
        case IFileType.UARPackage:
            return 'application/zip';
        default:
            return t.toString();
    }
};
export const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};
