import { Dispatch } from 'redux';
import { MathUtils } from 'three';
import { IFileType } from '~/../zw-api-client/src/zml';
import {
	IOnAddToLoadedAudio,
	IOnGetAudioFileInfo,
	IOnSetIs3dMode,
	IOnSetIsScreenRelativeMode,
	IOnSetMediaLibraryAudio,
	IOnSetMediaLibraryDisplay,
	IOnSetScreenRelativeDevice,
	IOnSetScreenRelativeDevicePreviousCustomDimensions,
	IOnSetTempAnimateModelAction,
	IOnSetTransformControlsActive,
	IOnSetVisibleCanvas,
	ISetProjectInfo
} from '.';
import { IAudio, IBackgroundContent } from '../../../typings';
import { IComponentType, IImage, IVideo } from '../../components/r3f/r3f-components/component-data-structure';
import { zwClient } from '../../sync/zapworks';
import { appendMp3, prependHttps, zmlToSpatialComponent } from '../../utils';
import {
	IAddMediaPositionTypes,
	IOnAddEntityIdsToSelection,
	IOnAddToast,
	IOnAddToLoadedMedia,
	IOnAddToLoadedMediaAction,
	IOnChangeZoomLevel,
	IOnCloseModal,
	IOnCopySelectedEntities,
	IOnEditSceneTitle,
	IOnFocusSceneNameInput, IOnGetZmlFileSearchInput, IOnOpenModal,
	IOnRemoveHotKey,
	IOnRemoveLoadedMedia,
	IOnRemoveSelection,
	IOnRemoveToast,
	IOnRemoveTrackingImageData,
	IOnScrollSceneMenu, IOnSetActiveEditingActionData, IOnSetActiveFaceLandmark,
	IOnSetActiveScene,
	IOnSetAudioStatus,
	IOnSetBackgroundHotSpotEnabled,
	IOnSetBorderRgba,
	IOnSetCanvasLoaded, IOnSetChromaKeyModalData, IOnSetCurrentSceneIndex,
	IOnSetCurrentUploads,
	IOnSetCurvatures,
	IOnSetDraggedEntityInfo,
	IOnSetDropdownHoveredFacelandmark,
	IOnSetEmitterAreaScales,
	IOnSetEmitterIsStatic,
	IOnSetEnlargeSceneMenu,
	IOnSetEntityMenuDragActive,
	IOnSetEntityMenuHotspotDragPosition,
	IOnSetFileVerifying,
	IOnSetFillRgba,
	IOnSetFontRgba,
	IOnSetGroupInversion,
	IOnSetHotKey,
	IOnSetLoadingMedia,
	IOnSetMarkerIndexPressed, IOnSetMultipleEntitySelectBoundary, IOnSetOpenEntityMenuCategoryDrawer, IOnSetOpenEntityMenuDrawer,
	IOnSetOpenInspectorMenuEntityShelf, IOnSetOpenProjectMenuDrawer, IOnSetPointerOffset, IOnSetPopOutIdealTopPosition, IOnSetPositioningIsActive,
	IOnSetPositions,
	IOnSetPreviewCodeSrc,
	IOnSetPureReactInCanvasHovered,
	IOnSetReplaceEntityMode,
	IOnSetRotationIsActive,
	IOnSetRotations,
	IOnSetScaleHotSpotEnabled,
	IOnSetScales,
	IOnSetSceneCarouselScrollLeft,
	IOnSetSceneEntityListVisible,
	IOnSetSceneSnapshots,
	IOnSetSelectedTemplateProjectId,
	IOnSetShowFaceLandmarks,
	IOnSetTargetReferenceObjectPosition,
	IOnSetTrackingImageData,
	IOnSetTransformControlsMode,
	IOnSetUserId,
	IOnUpdateUsers,
	IRemoveUpload,
	ISetLoadedAudioAction,
	ISetTrkImgIsUploading,
	IUserActionTypes,
	IZmlSearchResponseData,
	IOnSetActiveAnimationPreset,
	IOnSetPreviewUrl,
	InSetDragControlsUpdateTrigger,
	IOnSetTemporaryInitialDragRotation,
	IOnGet360BackgroundFileInfo,
	ISetLoaded360BackgroundAction,
	IOnSetVideoProgress,
	IOnSetPublishTrigger,
	IOnSetShowPublishPopup,
} from './action-types';


export const onSetPublishTrigger: IOnSetPublishTrigger = (payload) => ({
	type: IUserActionTypes.SET_PUBLISH_TRIGGER,
	payload
})

export const onSetShowPublishPopup: IOnSetShowPublishPopup = (payload) => ({
	type: IUserActionTypes.SET_SHOW_PUBLISH_POPUP,
	payload
})

export const onSetActiveEditingActionData: IOnSetActiveEditingActionData = (payload) => ({
	type: IUserActionTypes.SET_ACTIVE_EDITING_ACTION_DATA,
	payload
})

export const onSetChromaKeyModalData: IOnSetChromaKeyModalData = (payload) => ({
	type: IUserActionTypes.SET_CHROMA_KEY_EDIT_MODAL_DATA,
	payload,
});

export const onSetSelectedTemplateProject: IOnSetSelectedTemplateProjectId = (payload) => ({
	type: IUserActionTypes.SET_SELECTED_TEMPLATE_PROJECT,
	payload,
});

export const onSetActiveAnimationPreset: IOnSetActiveAnimationPreset = (payload) => ({
	type: IUserActionTypes.SET_ACTIVE_ANIMATION_PRESET,
	payload
})

export const onSetPreviewCodeSrc: IOnSetPreviewCodeSrc = (payload) => ({
	type: IUserActionTypes.SET_PREVIEW_CODE_SRC,
	payload,
});

export const onSetPreviewUrl: IOnSetPreviewUrl = (payload) => ({
	type: IUserActionTypes.SET_PREVIEW_URL,
	payload
});

export const onSetTargetImageReferenceObjectPosition: IOnSetTargetReferenceObjectPosition = (payload) => ({
	type: IUserActionTypes.SET_TARGET_IMAGE_REFERENCE_OBJECT_POSITION,
	payload,
});

export const onSetVideoProgress: IOnSetVideoProgress = (payload) => ({
	type: IUserActionTypes.SET_VIDEO_RENDITION_PROGRESS,
	payload
})

export const onSetPopOutIdealTopPosition: IOnSetPopOutIdealTopPosition = (payload) => ({
	type: IUserActionTypes.SET_POPOUT_IDEAL_TOP_POSITION,
	payload
})

export const onOpenModal: IOnOpenModal = (payload) => ({
	type: IUserActionTypes.OPEN_MODAL,
	payload,
});

export const onCloseModal: IOnCloseModal = () => ({
	type: IUserActionTypes.CLOSE_MODAL,
});

export const onSetEmitterIsStatic: IOnSetEmitterIsStatic = (payload) => ({
	type: IUserActionTypes.SET_EMITTER_IS_STATIC,
	payload,
});

export const onSetPositions: IOnSetPositions = (payload) => ({
	type: IUserActionTypes.SET_POSITIONS,
	payload,
});

export const onSetCurvatures: IOnSetCurvatures = (payload) => ({
	type: IUserActionTypes.SET_CURVATURES,
	payload,
});

export const onSetRotations: IOnSetRotations = (payload) => ({
	type: IUserActionTypes.SET_ROTATIONS,
	payload,
});

export const onSetScales: IOnSetScales = (payload) => ({
	type: IUserActionTypes.SET_SCALES,
	payload,
});

export const onSetEmitterAreaScales: IOnSetEmitterAreaScales = (payload) => ({
	type: IUserActionTypes.SET_EMITTER_AREA_SCALES,
	payload,
});

export const onSetFillRgba: IOnSetFillRgba = (payload) => ({
	type: IUserActionTypes.SET_FILL_RGBA,
	payload,
});

export const onSetFontRgba: IOnSetFontRgba = (payload) => ({
	type: IUserActionTypes.SET_FONT_RGBA,
	payload,
});

export const onSetBorderRgba: IOnSetBorderRgba = (payload) => ({
	type: IUserActionTypes.SET_BORDER_RGBA,
	payload,
});

export const onSetReplaceEntityMode: IOnSetReplaceEntityMode = (replaceEntityMode) => ({
	type: IUserActionTypes.SET_REPLACE_ENTITY_MODE,
	payload: { replaceEntityMode },
});

export const onSetMultipleEntitySelectBoundary: IOnSetMultipleEntitySelectBoundary = (payload) => ({
	type: IUserActionTypes.SET_MULTIPLE_ENTITY_SELECT_BOUNDARY,
	payload
})

export const onSetActiveFaceLandmark: IOnSetActiveFaceLandmark = (payload) => ({
	type: IUserActionTypes.SET_ACTIVE_FACE_LANDMARK,
	payload,
});

export const onSetTempAnimateModelAction: IOnSetTempAnimateModelAction = (action) => ({
	type: IUserActionTypes.SET_TEMP_ANIMATE_MODEL_ACTION,
	payload: { action },
});

export const onSetVisibleCanvas: IOnSetVisibleCanvas = (canvasName, visible) => ({
	type: IUserActionTypes.SET_VISIBLE_CANVAS,
	payload: { canvasName, visible },
});

export const onSetFileVerifying: IOnSetFileVerifying = (isVerifying) => ({
	type: IUserActionTypes.SET_FILE_VERIFYING,
	payload: { isVerifying },
});

export const onSetOpenInspectorMenuEntityShelf: IOnSetOpenInspectorMenuEntityShelf = (payload) => ({
	type: IUserActionTypes.SET_OPEN_INSPECTOR_MENU_ENTITY_SHELF,
	payload,
});

export const onSetOpenInspectorMenuProjectShelf: IOnSetOpenProjectMenuDrawer = (payload) => ({
	type: IUserActionTypes.SET_OPEN_PROJECT_MENU_DRAWER,
	payload,
})

export const onSetOpenEntityMenuCategoryDrawer: IOnSetOpenEntityMenuCategoryDrawer = (openEntityCategoryDrawerDict) => ({
	type: IUserActionTypes.SET_OPEN_ENTITY_MENU_CATEGORY_DRAWER,
	payload: { openEntityCategoryDrawerDict},
});

export const onSetOpenEntityMenuDrawer: IOnSetOpenEntityMenuDrawer = (openEntityMenuDrawer) => ({
	type: IUserActionTypes.SET_OPEN_ENTITY_MENU_DRAWER,
	payload: { openEntityMenuDrawer },
});

export const onSetScreenRelativeDevice: IOnSetScreenRelativeDevice = (deviceType) => ({
	type: IUserActionTypes.SET_SCREEN_RELATIVE_DEVICE,
	payload: deviceType,
});

export const onSetScreenRelativeDevicePreviousCustomDimensions: IOnSetScreenRelativeDevicePreviousCustomDimensions = (dimensions) => ({
	type: IUserActionTypes.SET_SCREEN_RELATIVE_DEVICE_PREVIOUS_CUSTOM_DIMENSIONS,
	payload: dimensions,
});

export const onSetUserId: IOnSetUserId = (userId) => ({
	type: IUserActionTypes.SET_USER_ID,
	payload: { userId },
});

export const onUpdateUsers: IOnUpdateUsers = (payload) => ({
	type: IUserActionTypes.UPDATE_USERS,
	payload,
});

export const onSetHotKey: IOnSetHotKey = (hotKey) => ({
	type: IUserActionTypes.SET_HOTKEY,
	payload: { hotKey },
});

export const onSetCanvasLoaded: IOnSetCanvasLoaded = (payload) => ({
	type: IUserActionTypes.CANVAS_IS_LOADED,
	payload,
});

export const onSetActiveScene: IOnSetActiveScene = (activeSceneProps) => ({
	type: IUserActionTypes.SET_ACTIVE_SCENE,
	payload: activeSceneProps,
});

export const onSetCurrentSceneIndex: IOnSetCurrentSceneIndex = (index) => ({
	type: IUserActionTypes.SET_CURRENT_SCENE_INDEX,
	payload: index,
});

export const onScrollSceneMenu: IOnScrollSceneMenu = (scrollMenuScene) => ({
	type: IUserActionTypes.SCROLL_SCENE_MENU,
	payload: scrollMenuScene,
});

export const onSetSceneSnapshots: IOnSetSceneSnapshots = (sceneSnapshotsDict) => ({
	type: IUserActionTypes.SET_SCENE_SNAPSHOTS,
	payload: sceneSnapshotsDict,
});

export const onRemoveHotKey: IOnRemoveHotKey = (hotKey) => ({
	type: IUserActionTypes.REMOVE_HOTKEY,
	payload: { hotKey },
});

export const onSetPositioningIsActive: IOnSetPositioningIsActive = (payload) => ({
	type: IUserActionTypes.SET_POSITIONING_ACTIVE,
	payload,
});

export const onSetRotationIsActive: IOnSetRotationIsActive = (payload) => ({
	type: IUserActionTypes.SET_ROTATION_ACTIVE,
	payload,
});

export const onSetScaleHotSpotEnabled: IOnSetScaleHotSpotEnabled = (scaleHotspotIsEnabled) => ({
	type: IUserActionTypes.SET_SCALE_HOTSPOT_ENABLED,
	payload: scaleHotspotIsEnabled,
});

export const onSetBackgroundHotSpotEnabled: IOnSetBackgroundHotSpotEnabled = (backgroundHotspotIsEnabled) => ({
	type: IUserActionTypes.SET_BACKGROUND_HOTSPOT_ENABLED,
	payload: backgroundHotspotIsEnabled,
});

export const onSetPureReactInCanvasHovered: IOnSetPureReactInCanvasHovered = (payload) => ({
	type: IUserActionTypes.SET_PURE_REACT_IN_CANVAS_HOVERED,
	payload,
});

export const onAddEntityIdsToSelection: IOnAddEntityIdsToSelection = (entityIds) => ({
	type: IUserActionTypes.ADD_ENTITY_IDS_TO_SELECTION,
	payload: entityIds,
});

export const onRemoveSelection: IOnRemoveSelection = (entityIds) => ({
	type: IUserActionTypes.REMOVE_SELECTION,
	payload: entityIds,
});

export const onChangeZoomLevel: IOnChangeZoomLevel = (zoomLevel) => ({
	type: IUserActionTypes.CHANGE_ZOOM_LEVEL,
	payload: zoomLevel,
});

export const onSetPointerOffset: IOnSetPointerOffset = (pointerOffset) => ({
	type: IUserActionTypes.SET_CLICK_OFFSET,
	payload: pointerOffset,
});

export const onSetMarkerIndexPressed: IOnSetMarkerIndexPressed = (markerIndex) => ({
	type: IUserActionTypes.SET_MARKER_INDEX_PRESSED,
	payload: markerIndex,
});

export const onSetGroupInversion: IOnSetGroupInversion = (axisInversion) => ({
	type: IUserActionTypes.SET_GROUP_INVERSION,
	payload: axisInversion,
});

export const onCopySelectedEntities: IOnCopySelectedEntities = (selectedIds) => ({
	type: IUserActionTypes.COPY_SELECTED_ENTITIES,
	payload: selectedIds,
});

export const onSetSceneEntityListVisible: IOnSetSceneEntityListVisible = (isVisibleProps) => ({
	type: IUserActionTypes.SET_SCENE_ENTITY_LIST_VISIBLE,
	payload: isVisibleProps,
});

// export const onSetSnapshotDict: IOnSetSnapshotDict = snapshotDict => ({
// 	type: IUserActionTypes.SET_SNAPSHOT_DICT,
// 	payload: snapshotDict,
// });

export const onSetSceneCarouselScrollLeft: IOnSetSceneCarouselScrollLeft = (scrollLeft) => ({
	type: IUserActionTypes.SET_SCENE_CAROUSEL_SCROLL_LEFT,
	payload: scrollLeft,
});

export const onFocusSceneNameInput: IOnFocusSceneNameInput = (boolean) => ({
	type: IUserActionTypes.FOCUS_SCENE_NAME_INPUT,
	payload: boolean,
});

export const onSetEnlargeSceneMenu: IOnSetEnlargeSceneMenu = (boolean) => ({
	type: IUserActionTypes.SET_SCENE_MENU_LARGE,
	payload: boolean,
});

export const onEditSceneTitle: IOnEditSceneTitle = (boolean) => ({
	type: IUserActionTypes.EDIT_SCENE_TITLE,
	payload: boolean,
});

export const onSetEntityMenuDragActive: IOnSetEntityMenuDragActive = (boolean) => ({
	type: IUserActionTypes.SET_ENTITY_MENU_DRAG_ACTIVE,
	payload: boolean,
});

export const onSetEntityMenuHotspotDragPosition: IOnSetEntityMenuHotspotDragPosition = (payload) => ({
	type: IUserActionTypes.SET_ENTITY_MENU_DRAG_HOTSPOT_POSITION,
	payload,
});

export const onSetDraggedEntityInfo: IOnSetDraggedEntityInfo = (payload) => ({
	type: IUserActionTypes.SET_ENTITY_MENU_DRAG_INFO,
	payload,
});

export const onSetLoadingMedia: IOnSetLoadingMedia = (payload) => ({
	type: IUserActionTypes.LOADING_MEDIA,
	payload,
});

export const onAddToLoadedMedia: IOnAddToLoadedMedia = (payload) => ({
	type: IUserActionTypes.ADD_TO_LOADED_MEDIA,
	payload,
});

export const onAddToLoadedAudio: IOnAddToLoadedAudio = (payload) => ({
	type: IUserActionTypes.ADD_TO_LOADED_AUDIO,
	payload,
});

export const onRemoveLoadedMedia: IOnRemoveLoadedMedia = (payload) => ({
	type: IUserActionTypes.REMOVE_LOADED_MEDIA,
	payload,
});

export const onRemoveUpload: IRemoveUpload = (payload) => ({
	type: IUserActionTypes.REMOVE_UPLOAD,
	payload,
});

export const onSetCurrentUploads: IOnSetCurrentUploads = (id, progress) => ({
	type: IUserActionTypes.SET_CURRENT_UPLOADS,
	payload: { id, progress },
});

export const onSetProjectInfo: ISetProjectInfo = (payload) => ({
	type: IUserActionTypes.SET_PROJECT_INFO,
	payload,
});

export const onSetAudioStatus: IOnSetAudioStatus = (payload) => ({
	type: IUserActionTypes.SET_AUDIO_STATUS,
	payload,
});

export const setTrkImgIsUploading: ISetTrkImgIsUploading = (payload) => ({
	type: IUserActionTypes.SET_TRACKING_IMG_UPLOADING,
	payload,
});

export const onSetTrackingImageData: IOnSetTrackingImageData = (payload) => ({
	type: IUserActionTypes.SET_TRACKING_IMG_DATA,
	payload,
});

export const onRemoveTrackingImageData: IOnRemoveTrackingImageData = () => ({
	type: IUserActionTypes.REMOVE_TRACKING_IMG_DATA,
});

export const onAddToast: IOnAddToast = (payload) => ({
	type: IUserActionTypes.ADD_TOAST,
	payload,
});

export const onRemoveToast: IOnRemoveToast = (payload) => ({
	type: IUserActionTypes.REMOVE_TOAST,
	payload,
});

export const onSetIs3dMode: IOnSetIs3dMode = (boolean) => ({
	type: IUserActionTypes.SET_3D_MODE,
	payload: boolean,
});

export const onSetIsScreenRelativeMode: IOnSetIsScreenRelativeMode = (boolean) => ({
	type: IUserActionTypes.SET_SCREEN_RELATIVE_MODE,
	payload: boolean,
});

export const onSetTransformControlsActive: IOnSetTransformControlsActive = (boolean) => ({
	type: IUserActionTypes.SET_TRANSFORM_CONTROLS_ACTIVE,
	payload: boolean,
});

export const onSetTransformControlsMode: IOnSetTransformControlsMode = (payload) => ({
	type: IUserActionTypes.SET_TRANSFORM_CONTROLS_MODE,
	payload,
});

export const onSetDragControlsUpdateTrigger: InSetDragControlsUpdateTrigger = (payload) => ({
	type: IUserActionTypes.SET_DRAG_CONTROLS_UPDATE_TRIGGER,
	payload,
});

export const onSetTemporaryInitialDragRotation: IOnSetTemporaryInitialDragRotation = (payload) => ({
	type: IUserActionTypes.SET_TEMP_INITIAL_ROTATION,
	payload,
})

export const onSetMediaLibraryDisplay: IOnSetMediaLibraryDisplay = (payload) => ({
	type: IUserActionTypes.SET_MEDIA_LIBRARY_DISPLAY,
	payload,
});

export const onSetMediaLibraryAudio: IOnSetMediaLibraryAudio = (payload) => ({
	type: IUserActionTypes.SET_MEDIA_LIBRARY_AUDIO,
	payload,
});

export const onSetShowFacelandmarks: IOnSetShowFaceLandmarks = (payload) => ({
	type: IUserActionTypes.SET_SHOW_FACE_LANDMARKS,
	payload,
});

export const onSetDropdownHoveredFacelandmark: IOnSetDropdownHoveredFacelandmark = (payload) => ({
	type: IUserActionTypes.SET_HOVERED_FACE_LANDMARK_DROPDOWN,
	payload,
});

export const componentToFileType = (componentType: IComponentType.Video | IComponentType.Image | IComponentType.Model3d) => {
	switch (componentType) {
		case IComponentType.Video:
			return IFileType.StreamingVideo;
		case IComponentType.Model3d:
			return IFileType.Model3D;
		case IComponentType.Image:
		default:
			return IFileType.Image;
	}
};



export function onGetZmlFileInfo(args: IOnGetZmlFileSearchInput): (dispatch: Dispatch) => void;
export function onGetZmlFileInfo(args: IZmlSearchResponseData): (dispatch: Dispatch) => void;
export function onGetZmlFileInfo(args: any): (dispatch: Dispatch) => void {
	const { type, limit, page, q, sort, status, isPublic, searchResponse  } = args as IOnGetZmlFileSearchInput & IZmlSearchResponseData;
	let entityData = searchResponse;
	return async (dispatch: Dispatch) => {
		if (!entityData) {
			if (!zwClient.zml) return;
			entityData = await zwClient.zml.search([componentToFileType(type)], page, q, limit, sort, status, isPublic);
		}
		// console.log('Entity Data ( from API ): ', entityData);
		for (let i = 0; i < entityData.results.length; i++) {
			const zmlEntity = entityData.results[i];
			const entity = await zmlToSpatialComponent(zmlEntity);
			const payload = {
				position: IAddMediaPositionTypes.date,
				entity: {...entity, isPublic: zmlEntity.public ?? false},
			};
			dispatch<IOnAddToLoadedMediaAction>({
				type: IUserActionTypes.ADD_TO_LOADED_MEDIA,
				payload,
			});
		}

		dispatch({
			type: IUserActionTypes.LOADING_MEDIA,
			payload: false,
		});
	};
}

// export const onGetZmlFileInfo: IOnGetZmlFileInfo = ({ type, limit, page, q, sort, status, isPublic  }) => {
// 	return async (dispatch: Dispatch) => {
// 		if (!zwClient.zml) return;
// 		const entityData = await zwClient.zml.search([componentToFileType(type)], page, q, limit, sort, status, isPublic);
// 		// console.log('Entity Data ( from API ): ', entityData);
// 		for (let i = 0; i < entityData.results.length; i++) {
// 			const zmlEntity = entityData.results[i];
// 			const entity = await zmlToSpatialComponent(zmlEntity);
// 			const payload = {
// 				position: IAddMediaPositionTypes.date,
// 				entity: {...entity, isPublic: zmlEntity.public},
// 			};
// 			dispatch<IOnAddToLoadedMediaAction>({
// 				type: IUserActionTypes.ADD_TO_LOADED_MEDIA,
// 				payload,
// 			});
// 		}

// 		dispatch({
// 			type: IUserActionTypes.LOADING_MEDIA,
// 			payload: false,
// 		});
// 	};
// };

export const onGetAudioFileInfo: IOnGetAudioFileInfo = ({limit}) => {
	return async (dispatch: Dispatch) => {
		if (!zwClient.zml) return;
		const audioData = await zwClient.zml.search([IFileType.Audio], undefined, undefined, limit);
		const audioInfoArray: IAudio[] = audioData.results.map((audio) => {
			return {
				id: MathUtils.generateUUID(),
				zmlFileId: audio.id,
				url: prependHttps(appendMp3(audio.url)),
				name: audio.caption,
			};
		});
		dispatch<ISetLoadedAudioAction>({
			type: IUserActionTypes.SET_LOADED_AUDIO,
			payload: audioInfoArray,
		});

		dispatch({
			type: IUserActionTypes.LOADING_MEDIA,
			payload: false,
		});
	};
};

export const onGet360BackgroundFileInfo: IOnGet360BackgroundFileInfo = ({limit}) => {
	return async (dispatch: Dispatch) => {
		if (!zwClient.zml) return;
		const backgroundData = await zwClient.zml.search([IFileType.Image360, IFileType.Video360], undefined, undefined, limit);
		const backgroundContentArray: (IBackgroundContent & {statusUrl: string})[] = backgroundData.results.map((asset) => {
			return {
				id: MathUtils.generateUUID(),
				type: asset.type as IFileType.Image360 | IFileType.Video360,
				zmlFileId: asset.id,
				url: prependHttps(asset.url),
				name: asset.caption,
				statusUrl: asset.statusURL
			};
		});
		dispatch<ISetLoaded360BackgroundAction>({
			type: IUserActionTypes.SET_LOADED_360_BACKGROUND,
			payload: backgroundContentArray,
		});

		dispatch({
			type: IUserActionTypes.LOADING_MEDIA,
			payload: false,
		});
	};
};
