export var ProjectTool;
(function (ProjectTool) {
    ProjectTool["Designer"] = "designer-2";
    ProjectTool["Mattercraft"] = "mattercraft-beta";
})(ProjectTool || (ProjectTool = {}));
export var IProjectTemplateCategories;
(function (IProjectTemplateCategories) {
    IProjectTemplateCategories["Image"] = "image";
    IProjectTemplateCategories["World"] = "world";
    IProjectTemplateCategories["Face"] = "face";
    IProjectTemplateCategories["Content360"] = "360";
})(IProjectTemplateCategories || (IProjectTemplateCategories = {}));
export const PROJECT_TEMPLATE_CATEGORIES = [IProjectTemplateCategories.Image, IProjectTemplateCategories.World, IProjectTemplateCategories.Face, IProjectTemplateCategories.Content360];
