import { IAbstractComponentChildrenById, IAbstractComponentCopyInclChildren, IOldToNewIdDict } from '../../../typings/general';
import { IAbstractComponentUnion, IActionDict, IComponentsById, IComponentType,  IScreenAnchorGroup,  IScreenContent,  ISpatialComponentActionData, ISpatialComponentUnion } from '../../components/r3f/r3f-components/component-data-structure/index';
import { isAbstractComponent } from '../../components/r3f/r3f-components/utils/general';
import { addToDictArrayValue } from '../data-structure-utils';
import { getAbstractComponentId } from './get';

/**
 * If key exists for id, then use its value for copy
 * If no key exists for id, then discard this action
 */
export const copyComponentActionDict = (actionDict: IActionDict, oldToNewIdDict: IOldToNewIdDict): IActionDict | null => {
	const actionDictCp = JSON.parse(JSON.stringify(actionDict)) as IActionDict;
	const retActionDict = {} as IActionDict;

	for (const triggerType in actionDictCp) {
		const actionArray = actionDictCp[triggerType as keyof typeof actionDictCp];
		if (!actionArray) return null;
		actionArrayLoop: for (let i = 0; i < actionArray.length; i++) {
			const actionCp = JSON.parse(JSON.stringify(actionArray[i])) as ISpatialComponentActionData;
			
			// If target ID type action then adjust IDs
			if ('targetIds' in actionCp) {
				const targetIds = actionCp.targetIds;
				for (let j = 0; j < targetIds.length; j++) {
					const newTargetId = oldToNewIdDict[actionCp.targetIds[j]];
					if (oldToNewIdDict[targetIds[j]] && newTargetId) {
						actionCp.targetIds[j] = newTargetId;
					} else {
						// There is a target ID by no new corresponding ID - don't include this action
						continue actionArrayLoop;
					}
				}
			}
			addToDictArrayValue(retActionDict, triggerType, actionCp);
		}
	}
	if (Object.keys(retActionDict).length === 0) return null;
	return retActionDict;
};

export const copySpatialComponent = <T extends ISpatialComponentUnion>(args: { spatialComponent: T, oldToNewIdDict: IOldToNewIdDict }): T | null => {
	const { spatialComponent, oldToNewIdDict } = args;
	// console.log('Copying spatial component: ', spatialComponent)
	const spatialComponentCp = JSON.parse(JSON.stringify(spatialComponent));
	spatialComponentCp.id = oldToNewIdDict[spatialComponentCp.id];
	if ('actions' in spatialComponentCp) {
		// Generate a safe action dict copy and if it's not null, use it otherwise delete the existing actions property
		const safeActionsDict = copyComponentActionDict(spatialComponentCp.actions, oldToNewIdDict);
		if (safeActionsDict) {
			spatialComponentCp.actions = safeActionsDict;
		} else {
			delete spatialComponentCp.actions;
		}
	}
	return spatialComponentCp;
}

export const copyAbstractComponent = <T extends IAbstractComponentUnion>(
	args: {abstractComponent: T,
	oldToNewIdDict: IOldToNewIdDict,
	componentsById: IComponentsById,
	parentId: string}
): IAbstractComponentCopyInclChildren<T> | null => {
	const { abstractComponent, oldToNewIdDict, componentsById, parentId } = args;
	// console.log('Copying abstract component: ', abstractComponent)
	if (abstractComponent.type === IComponentType.Root) return null;
	const abstractComponentCp = JSON.parse(JSON.stringify(abstractComponent)) as T;
	const abstractComponentChildrenById: IAbstractComponentChildrenById = {};
	let sceneId = Object.keys(oldToNewIdDict).filter((oldId) => {
		return componentsById[oldId].type === IComponentType.Scene;
	})[0];

	if (abstractComponentCp.type === IComponentType.Scene && abstractComponentCp.actions) {
		// Generate a safe action dict copy and if it's not null, use it otherwise delete the existing actions property
		const safeActionsDict = copyComponentActionDict(abstractComponentCp.actions, oldToNewIdDict);
		if (safeActionsDict) {
			abstractComponentCp.actions = safeActionsDict;
		} else {
			delete abstractComponentCp.actions;
		}
	}

	// TODO - If copying screen content only _ TEMP
	if (abstractComponentCp.type === IComponentType.ScreenAnchorGroup) {
		// sceneId is the component of type scene, which has parentId in it's children array
		for (const componentId in componentsById) {
			const comp = componentsById[componentId];
			if (comp.type === IComponentType.Scene && comp.children.includes(parentId)) {
				sceneId = componentId;
			}
		}
	}

	const abstractComponentId = getAbstractComponentId(abstractComponentCp, sceneId ?? parentId, componentsById);
	if (!abstractComponentId) {
		console.log('no abstractComponentId for ', abstractComponent, 'with parentId : ', parentId);
		return null;
	}
	const newAbstractComponentId = oldToNewIdDict[abstractComponentId];
	if (!newAbstractComponentId) {
		console.log('no new abstract component id for abstractComponentId: ', abstractComponentId)	
		return null
	}

	for (let i = 0; i < abstractComponentCp.children.length; i++) {
		const currentId = abstractComponentCp.children[i];		
		const childComponent = componentsById[currentId];
		const componentCopy = isAbstractComponent(childComponent) ? copyAbstractComponent({abstractComponent: childComponent, oldToNewIdDict, componentsById, parentId: abstractComponentId}) : copySpatialComponent({spatialComponent: childComponent, oldToNewIdDict});
		if (!componentCopy) return null;
		const newId = oldToNewIdDict[currentId];
		if (!newId) continue;
		abstractComponentCp.children[i] = newId;
		abstractComponentChildrenById[newId] = componentCopy;
	}

	return {
		abstractComponentCopy: { [newAbstractComponentId]: abstractComponentCp },
		abstractComponentChildrenCopy: abstractComponentChildrenById
	};
};

